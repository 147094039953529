import { Card } from 'antd';

import { CardFiller } from 'components';

import OutlayForm from './OutlayForm';
import infoCardLayout from './infoCardLayout';
import styles from './OutlayParamDrawer.module.scss';

const InfoCard = ({ data, isDisabled }) => (
  <Card
    className={styles.InfoCard}
    title={`${data?.type === 'act' ? 'Акт' : 'Смета'}\u{2009}${data.id}`}
  >
    <CardFiller layout={infoCardLayout} data={data} isDisabled={isDisabled} />
    <OutlayForm data={data} isDisabled={isDisabled} />
  </Card>
);

export default InfoCard;
