import {
  APP_LOAD_FILE,
  HEADER_CHANGE_APP_STATUS,
} from './constants';

export const loadFile = ({ deal, outlay }, shouldOverwrite) => ({
  type: APP_LOAD_FILE,
  payload: { deal, outlay, shouldOverwrite },
});

export const changeAppStatus = (status) => ({
  type: HEADER_CHANGE_APP_STATUS,
  payload: { status },
});
