export const getStateData = (state) => {
  if (!state.deal.selectedDeal) return null;
  if (!state.outlay.selectedOutlay) return null;

  return [state.deal.selectedDeal, state.outlay.selectedOutlay];
};

export const getIdbData = async () => {
  if (!window?.versionIdb) return null;

  const resultPromise = Promise.all([
    window.versionIdb.getAll('workTypes'),
    window.versionIdb.getAll('materialTypes'),
    window.versionIdb.getAll('outlayVariables'),
    window.versionIdb.getAll('discountConstraints'),
  ]);

  try {
    const result = await resultPromise;
    return result;
  } catch (error) {
    // logger.error('get_idb_data', error);
    return null;
  }
};
