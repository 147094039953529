import { FocusEvent, MouseEvent, useEffect, useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';

import { cut } from 'shared/format';

import styles from './DecInput.module.scss';

export type DecInputProps = Omit<
  InputNumberProps<string>,
  | 'parser'
  | 'formatter'
  | 'precision'
  | 'onBlur'
  // | 'onChange'
  | 'color'
> & {
  scale?: number,
  onNativeChange?: (value: string, reset: () => void) => void,
  color?: 'red' | 'orange' | 'green' | 'theme',
};

const DOT = '.';
const COMMA = ',';
const parser = (value?: string) => value?.replace(COMMA, DOT) ?? '';
// const formatter = (value?: string) => value?.replace(DOT, COMMA) ?? '';

const defOnFocus = (event: FocusEvent<HTMLInputElement>) => event.currentTarget.select();
const defOnClick = (event: MouseEvent<HTMLInputElement>) => event.stopPropagation();

export const DecInput = ({
  scale = 2,
  value,
  color,
  onChange,
  onNativeChange,
  onClick = defOnClick,
  onFocus = defOnFocus,
  ...props
}: DecInputProps) => {
  const [inputValue, setInputValue] = useState<string | null>(() => cut(value, scale));

  useEffect(() => {
    const cutValue = cut(value, scale);
    if (cutValue === inputValue) return;
    setInputValue(cutValue);
  }, [value, scale]);

  return <InputNumber<string>
    {...props}
    stringMode
    inputMode='decimal'
    precision={scale}
    parser={parser}
    // formatter={formatter}
    value={inputValue}
    onClick={onClick}
    onFocus={onFocus}
    className={styles.Container}
    data-color={color}
    onChange={(changeValue) => {
      const cutValue = changeValue && cut(changeValue, scale);
      if (cutValue === inputValue) return;
      setInputValue(cutValue);
      onChange?.(cutValue || cut(changeValue, scale));
    }}
    onBlur={() => {
      const cutValue = cut(value, scale);
      if (cutValue === inputValue) return;
      const cutInputValue = cut(inputValue, scale);
      onNativeChange?.(cutInputValue, () => setInputValue(cutValue));
      if (inputValue !== cutInputValue) setInputValue(cutInputValue);
    }}
  />;
};