import { useMemo, useSyncExternalStore } from 'react';
import { getMediaQuery } from './utils';

type MediaQueryStoreArg = [(notify: () => void) => () => void, () => boolean];

const maxWidth_mediaQueryStoreArg_mapper = (maxWidth: number): MediaQueryStoreArg => {
  const mediaQuery = getMediaQuery(`(max-width: ${maxWidth}px)`);

  return [
    (notify: () => void) => {
      mediaQuery.addEventListener('change', notify);
      return () => mediaQuery.removeEventListener('change', notify);
    },
    () => mediaQuery.matches,
  ];
};

const mediaQueryStoreArg_syncStoreHook_mapper = (args: MediaQueryStoreArg) => useSyncExternalStore(...args);

export const buildMaxWidthMatcherHook = (...maxWidths: number[]) => {
  const mediaQueryStoreArgs = maxWidths.map(maxWidth_mediaQueryStoreArg_mapper);

  return () => {
    const mediaQueryMatches = mediaQueryStoreArgs.map(mediaQueryStoreArg_syncStoreHook_mapper);

    return useMemo(() => {
      for (let idx = 0; idx < mediaQueryMatches.length; idx++) {
        if (mediaQueryMatches[idx]) return idx;
      }
      return mediaQueryMatches.length;
    }, mediaQueryMatches);
  };
};
