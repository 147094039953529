import { reduceThunk } from './reduceThunk';

export class Updater {
  addAction(lastAction) {
    const thunk = reduceThunk(lastAction);
    if (!thunk) return;
    thunk();
  }
}

export const getUpdater = () => window.updater || (window.updater = new Updater());
