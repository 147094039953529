import { Lexer, createToken } from 'chevrotain';

import { LOCAL_VAR_RGX, GLOBAL_VAR_RGX } from '../const';

export const Space = createToken({ name: 'Whitespace', pattern: /\s+/, group: Lexer.SKIPPED });

export const IfFunction = createToken({ name: 'If Function', pattern: /IF\(/, label: 'IF' });
export const LParen = createToken({ name: 'Left Parenthesis', pattern: /\(/, label: '(' });
export const RParen = createToken({ name: 'Right Parenthesis', pattern: /\)/, label: ')' });
export const Comma = createToken({ name: 'Comma', pattern: /,/, label: ',' });

export const LocalVar = createToken({ name: 'Local Var', pattern: new RegExp(LOCAL_VAR_RGX, '') });
export const GlobalVar = createToken({ name: 'Global Var', pattern: new RegExp(GLOBAL_VAR_RGX, '') });

export const Bool = createToken({ name: 'Boolean', pattern: /TRUE|FALSE/, label: '<bool>' });
export const Float = createToken({ name: 'Float', pattern: /[0-9]+\.?[0-9]*/, label: '<f64>' });

export const MulOp = createToken({ name: 'Multiplicative Operator', pattern: Lexer.NA });
export const MulSign = createToken({ name: 'Mul Sign', pattern: /\*/, label: '*', categories: MulOp });
export const DivSign = createToken({ name: 'Div Sign', pattern: /\//, label: '/', categories: MulOp });

export const AddOp = createToken({ name: 'Additive Operator', pattern: Lexer.NA });
export const AddSign = createToken({ name: 'Add Sign', pattern: /\+/, label: '+', categories: AddOp });
export const SubSign = createToken({ name: 'Sub Sign', pattern: /-/, label: '-', categories: AddOp });

export const CompOp = createToken({ name: 'Comparison Operator', pattern: Lexer.NA });
export const EqSign = createToken({ name: 'Eq Sign', pattern: /=/, label: '=', categories: CompOp });
export const GteSign = createToken({ name: 'GTE Sign', pattern: />=/, label: '>=', categories: CompOp });
export const LteSign = createToken({ name: 'LTE Sign', pattern: /<=/, label: '<=', categories: CompOp });
export const GtSign = createToken({ name: 'GT Sign', pattern: />/, label: '>', categories: CompOp });
export const LtSign = createToken({ name: 'LT Sign', pattern: /</, label: '<', categories: CompOp });

export const allTokens = [
  Space,
  IfFunction,
  LParen,
  RParen,
  Comma,
  LocalVar,
  GlobalVar,
  Bool,
  Float,
  MulSign,
  DivSign,
  AddSign,
  SubSign,
  EqSign,
  GteSign,
  LteSign,
  GtSign,
  LtSign,
  MulOp,
  AddOp,
  CompOp,
];

const lexer = new Lexer(allTokens, {
  positionTracking: 'onlyOffset',
  skipValidations: __APP_ENV__ === 'production',
  ensureOptimizations: __APP_ENV__ !== 'production',
});

export const lex = (input: string) => lexer.tokenize(input);
