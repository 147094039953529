import { Button, Card, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DecInput } from 'components';
import { round } from 'shared/utils';
import { ternarize } from 'shared/format';
import { editSubAct } from 'store/Outlay/actions';
import { dispatch } from 'store';

const createPrepaymentCard = (isNP) => {
  const key = `prepayment${isNP ? 'NP' : ''}`;
  const title = `Предоплата${isNP ? ' НП' : ''}`;
  const inputKey = `ACT_PRE${isNP ? '_NP' : ''}-`;

  return ({ isDisabled }) => {
    const deal = useSelector(({ deal }) => deal.selectedDeal ?? null);
    const outlay = useSelector(({ outlay }) => outlay.selectedOutlay ?? null);
  
    const remainingPrepayment = useMemo(() => {
      if (!outlay?.subActs?.length)
        return deal?.[key] ?? 0;
  
      let total = deal?.[key] ?? 0;
      for (const act of outlay.subActs)
        total = round(total - act[key]);
      return total;
    }, [outlay]);
  
    return (
      <Card
        title={title}
        extra={`Осталось: ${ternarize(remainingPrepayment, { min: 2, max: 2 })}`}
        bodyStyle={{ display: 'flex', flexFlow: 'column nowrap', gap: '14px' }}
      >
        {outlay?.subActs?.length && outlay.subActs.map(({ actNumber, [key]: prepayment }) => {
          if (actNumber === 0) return null;
  
          const max = round(+prepayment + remainingPrepayment);
          const setValue = (value = 0) => dispatch(editSubAct(outlay?.id, actNumber, { [key]: value }));
  
          return <DecInput
            key={`${inputKey}${actNumber}`}
            disabled={isDisabled}
            min='0'
            max={max}
            size='middle'
            value={prepayment}
            onNativeChange={(value) => setValue(value)}
            addonBefore={`№${actNumber}`}
            addonAfter={(
              <Tooltip placement='topLeft' title='Поставить остаток'>
                <Button type='link' onClick={() => setValue(max)}>
                  ₽
                </Button>
              </Tooltip>
            )}
          />;
        })}
      </Card>
    );
  };
};

export const PrepaymentCard = createPrepaymentCard(false);
export const PrepaymentCardNP = createPrepaymentCard(true);
