import { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import { Navigate, createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import { store } from 'store';
import { Prompt } from 'Prompt';
import { GoogleAuth, TotalSpinner } from 'components';

const SPACE = { size: 16 };
const FORM = { validateMessages: { required: 'Заполните поле' } };

const AuthPage = lazy(() => import('./AuthPage/AuthPage'));
const PageLayout = lazy(() => import('./PageLayout/PageLayout'));
const DealListPage = lazy(() => import('./DealListPage/DealListPage'));
const OutlayListPage = lazy(() => import('./OutlayListPage/OutlayListPage'));
const RoomListPage = lazy(() => import('./RoomListPage/RoomListPage'));
const BoopPage = lazy(() => import('./BoopPage/BoopPage'));
const ResetPage = lazy(() => import('./ResetPage/ResetPage'));

window.router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path='/' element={<AuthPage />} />
    <Route path='deals' element={<PageLayout />}>
      <Route path='' element={<DealListPage />} />
      <Route path=':deal' element={<OutlayListPage />} />
      <Route path=':deal/outlay/:outlay' element={<RoomListPage />} />
    </Route>
    <Route path='boop' element={<BoopPage />} />
    <Route path='backend/google' element={<GoogleAuth />} />
    <Route path='reset' element={<ResetPage />} />
    <Route path='*' element={<Navigate to='/' replace={true} />} />
  </>
));

const App = () => (
  <div className='app'>
    <ConfigProvider
      componentSize='small'
      form={FORM}
      space={SPACE}
      locale={locale}
    >
      <Suspense fallback={<TotalSpinner />}>
        <Provider store={store}>
          <RouterProvider router={window.router} fallbackElement={<TotalSpinner />} />
        </Provider>
      </Suspense>
    </ConfigProvider>
    <Prompt />
  </div>
);

export default App;
