import { decNestingLevel, incNestingLevel } from './store';
import { buildMethod, getPrefixArgs } from './utils';

export const logger = {
  ...buildMethod('debug'),
  ...buildMethod('log'),
  ...buildMethod('warn'),
  ...buildMethod('error'),
  group: (prefix?: string | null, ...args: any[]) => {
    console.groupCollapsed(...getPrefixArgs('group', prefix), ...args);
    incNestingLevel();
  },
  groupEnd: () => {
    console.groupEnd();
    decNestingLevel();
  },
};
