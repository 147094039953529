import { Drawer, Badge, Button } from 'antd';

import InfoCard from './InfoCard';
import styles from './OutlayParamDrawer.module.scss';

import deriveOutlayRibbon from 'shared/deriveOutlayRibbon/deriveOutlayRibbon';
import { PrepaymentCard, PrepaymentCardNP } from './PrepaymentCard';
import { useIsLocked } from 'shared/hooks';

const OutlayParamDrawer = ({ data = {}, ...restProps }) => {
  const isLocked = useIsLocked();

  return (
    <Drawer
      autoFocus
      destroyOnClose
      className={styles.Container}
      closable={false}
      onClose={data.setIsVisible}
      footer={
        <Button onClick={data.setIsVisible} type='primary' size='large' block>
          Закрыть
        </Button>
      }
      {...restProps}
    >
        <Badge.Ribbon {...deriveOutlayRibbon(data)}>
          <InfoCard data={data} isDisabled={isLocked} />
        </Badge.Ribbon>
        {data?.type === 'act' && <PrepaymentCard isDisabled={isLocked} />}
        {data?.type === 'act' && <PrepaymentCardNP isDisabled={isLocked} />}
    </Drawer>
  );
};

export default OutlayParamDrawer;
