type FetchErrorOptions = {
  code?: string,
  message?: string,
  status?: number,
  data?: { [key: string]: any } | any[],
};

export class FetchError extends Error {
  public code: string;
  public status: number;
  public data: { [key: string]: any } | any[];

  constructor(options: FetchErrorOptions = {}) {
    const {
      code = 'FetchError',
      message = 'Произошла неизвестная ошибка. Проверьте соединение и/или обратитесь к администратору.',
      status = 503,
      data = {},
    } = options;

    super(message);
    this.name = 'FetchError';
    this.code = code;
    this.status = status;
    this.data = data;
  }
}
