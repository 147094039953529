if (!window.requestIdleCallback) window.requestIdleCallback = function requestIdleCallback(callback: IdleRequestCallback): number {
  const start = Date.now();
  return setTimeout(() => callback({
    didTimeout: false,
    timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
  }), 1) as any;
};

if (!window.cancelIdleCallback) window.cancelIdleCallback = window.clearTimeout;

export {};
