import { DecRuleSet, GramCaseMap, GramGenderMap, NameTupleMap } from './types';

export const NAME_MAP: NameTupleMap = { lastName: 0, firstName: 1, middleName: 2, gender: 3 } as const;

export const GENDER_MAP: GramGenderMap = { masculine: 0, feminine: 1, neuter: 2 } as const;

export const CASE_MAP: GramCaseMap = { gen: 0, dat: 1, acc: 2, ins: 3, prep: 4 } as const;

export const DEC_RULE_SETS: [lastName: DecRuleSet, firstName: DecRuleSet, middleName: DecRuleSet] = [
  {
    irregular: [
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'бонч',
          'абдул',
          'белиц',
          'гасан',
          'дюссар',
          'дюмон',
          'книппер',
          'корвин',
          'ван',
          'дер',
          'шолом',
          'тер',
          'призван',
          'мелик',
          'вар',
          'фон',
        ],
        mods: null,
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'дюма',
          'тома',
          'дега',
          'люка',
          'ферма',
          'гамарра',
          'петипа',
          'шандра',
          'скаля',
          'каруана'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'гусь',
          'ремень',
          'камень',
          'онук',
          'богода',
          'нечипас',
          'долгопалец',
          'маненок',
          'рева',
          'кива'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'вий',
          'сой',
          'цой',
          'хой'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'я'
        ],
        mods: null
      }
    ],
    regular: [
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'б',
          'в',
          'г',
          'д',
          'ж',
          'з',
          'й',
          'к',
          'л',
          'м',
          'н',
          'п',
          'р',
          'с',
          'т',
          'ф',
          'х',
          'ц',
          'ч',
          'ш',
          'щ',
          'ъ',
          'ь'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'гава',
          'орота'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'ска',
          'цка'
        ],
        mods: [
          '-ой',
          '-ой',
          '-ую',
          '-ой',
          '-ой'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'цкая',
          'ская',
          'ная',
          'ая'
        ],
        mods: [
          '--ой',
          '--ой',
          '--ую',
          '--ой',
          '--ой'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'яя'
        ],
        mods: [
          '--ей',
          '--ей',
          '--юю',
          '--ей',
          '--ей'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'на'
        ],
        mods: [
          '-ой',
          '-ой',
          '-у',
          '-ой',
          '-ой'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'иной'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'уй'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'ца'
        ],
        mods: [
          '-ы',
          '-е',
          '-у',
          '-ей',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'рих'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ом',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'ия'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'иа',
          'аа',
          'оа',
          'уа',
          'ыа',
          'еа',
          'юа',
          'эа'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'их',
          'ых'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'о',
          'е',
          'э',
          'и',
          'ы',
          'у',
          'ю'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'ова',
          'ева'
        ],
        mods: [
          '-ой',
          '-ой',
          '-у',
          '-ой',
          '-ой'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'га',
          'ка',
          'ха',
          'ча',
          'ща',
          'жа',
          'ша'
        ],
        mods: [
          '-и',
          '-е',
          '-у',
          '-ой',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'а'
        ],
        mods: [
          '-ы',
          '-е',
          '-у',
          '-ой',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ь'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'ия'
        ],
        mods: [
          '-и',
          '-и',
          '-ю',
          '-ей',
          '-и'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'я'
        ],
        mods: [
          '-и',
          '-е',
          '-ю',
          '-ей',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ей'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ян',
          'ан',
          'йн'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ом',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ынец',
          'обец'
        ],
        mods: [
          '--ца',
          '--цу',
          '--ца',
          '--цем',
          '--це'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'онец',
          'овец'
        ],
        mods: [
          '--ца',
          '--цу',
          '--ца',
          '--цом',
          '--це'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ай'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'кой'
        ],
        mods: [
          '-го',
          '-му',
          '-го',
          '--им',
          '-м'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'гой'
        ],
        mods: [
          '-го',
          '-му',
          '-го',
          '--им',
          '-м'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ой'
        ],
        mods: [
          '-го',
          '-му',
          '-го',
          '--ым',
          '-м'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ах',
          'ив'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ом',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ший',
          'щий',
          'жий',
          'ний'
        ],
        mods: [
          '--его',
          '--ему',
          '--его',
          '-м',
          '--ем'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ый'
        ],
        mods: [
          '--ого',
          '--ому',
          '--ого',
          '-м',
          '--ом'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'кий'
        ],
        mods: [
          '--ого',
          '--ому',
          '--ого',
          '-м',
          '--ом'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ий'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-и'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ок'
        ],
        mods: [
          '--ка',
          '--ку',
          '--ка',
          '--ком',
          '--ке'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ец'
        ],
        mods: [
          '--ца',
          '--цу',
          '--ца',
          '--цом',
          '--це'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ц',
          'ч',
          'ш',
          'щ'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ем',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ен',
          'нн',
          'он',
          'ун'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ом',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'в',
          'н'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ым',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'б',
          'г',
          'д',
          'ж',
          'з',
          'к',
          'л',
          'м',
          'п',
          'р',
          'с',
          'т',
          'ф',
          'х'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ом',
          'е'
        ]
      }
    ]
  },
  {
    irregular: [
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'лев'
        ],
        mods: [
          '--ьва',
          '--ьву',
          '--ьва',
          '--ьвом',
          '--ьве'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'пётр'
        ],
        mods: [
          '---етра',
          '---етру',
          '---етра',
          '---етром',
          '---етре'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'павел'
        ],
        mods: [
          '--ла',
          '--лу',
          '--ла',
          '--лом',
          '--ле'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'яша'
        ],
        mods: [
          '-и',
          '-е',
          '-у',
          '-ей',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'шота'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'рашель',
          'нинель',
          'николь',
          'габриэль',
          'даниэль'
        ],
        mods: null
      }
    ],
    regular: [
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'е',
          'ё',
          'и',
          'о',
          'у',
          'ы',
          'э',
          'ю'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'б',
          'в',
          'г',
          'д',
          'ж',
          'з',
          'й',
          'к',
          'л',
          'м',
          'н',
          'п',
          'р',
          'с',
          'т',
          'ф',
          'х',
          'ц',
          'ч',
          'ш',
          'щ',
          'ъ'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'ь'
        ],
        mods: [
          '-и',
          '-и',
          null,
          'ю',
          '-и'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ь'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'га',
          'ка',
          'ха',
          'ча',
          'ща',
          'жа'
        ],
        mods: [
          '-и',
          '-е',
          '-у',
          '-ой',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'ша'
        ],
        mods: [
          '-и',
          '-е',
          '-у',
          '-ей',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'а'
        ],
        mods: [
          '-ы',
          '-е',
          '-у',
          '-ой',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'ия'
        ],
        mods: [
          '-и',
          '-и',
          '-ю',
          '-ей',
          '-и'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'а'
        ],
        mods: [
          '-ы',
          '-е',
          '-у',
          '-ой',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'я'
        ],
        mods: [
          '-и',
          '-е',
          '-ю',
          '-ей',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ия'
        ],
        mods: [
          '-и',
          '-и',
          '-ю',
          '-ей',
          '-и'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'я'
        ],
        mods: [
          '-и',
          '-е',
          '-ю',
          '-ей',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ей'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ий'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-и'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'й'
        ],
        mods: [
          '-я',
          '-ю',
          '-я',
          '-ем',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'б',
          'в',
          'г',
          'д',
          'ж',
          'з',
          'к',
          'л',
          'м',
          'н',
          'п',
          'р',
          'с',
          'т',
          'ф',
          'х',
          'ц',
          'ч'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ом',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.neuter,
        tests: [
          'ния',
          'рия',
          'вия'
        ],
        mods: [
          '-и',
          '-и',
          '-ю',
          '-ем',
          '-ем'
        ]
      }
    ]
  },
  {
    regular: [
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'ич'
        ],
        mods: [
          'а',
          'у',
          'а',
          'ем',
          'е'
        ]
      },
      {
        genderIdx: GENDER_MAP.masculine,
        tests: [
          'оглы',
          'оглу'
        ],
        mods: null
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'на'
        ],
        mods: [
          '-ы',
          '-е',
          '-у',
          '-ой',
          '-е'
        ]
      },
      {
        genderIdx: GENDER_MAP.feminine,
        tests: [
          'кызы',
          'кузу'
        ],
        mods: null
      }
    ]
  },
];
