import {
  HEADER_CHANGE_APP_STATUS,
} from './constants';

const initialState = {
  appStatus: 1,
};

export const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case HEADER_CHANGE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload.status,
      };
    default:
      return state;
  }
};
