import { logger, perf } from "shared/utils";
import { dispatch, store } from "store";
import { changeAppStatus } from "store/actions";
import { reduceThunk } from "./reduceThunk";
import { Button } from "antd";
import { saveToFile } from "shared/crypto";

const ONLINE = 1;
const SYNCING = 2;
const OFFLINE = 3;

const SEC_IN_MS = 1000;
const FIVE_SEC_IN_MS = 5 * SEC_IN_MS;

const CYCLE_EVENT = new Event('cycle');

const NOTE_BUTTON = (
  <Button type='primary' onClick={saveToFile}>
    Сохранить
  </Button>
);

export class Saver extends EventTarget {
  constructor() {
    super();

    this.requests = 0;
  }

  async next(thunk, attempts = 0) {
    let status = store.getState().app.appStatus;

    if (!window.navigator.onLine || status === OFFLINE) {
      if (status !== OFFLINE) dispatch(changeAppStatus(OFFLINE));
      return setTimeout(() => this.next(thunk), SEC_IN_MS);
    }

    if (this.requests === 0) {
      if (status === SYNCING) dispatch(changeAppStatus(ONLINE));
      return setTimeout(() => this.next(thunk), SEC_IN_MS);

    } else if (status !== SYNCING) {
      status = SYNCING;
      dispatch(changeAppStatus(SYNCING));
    }

    const timer = perf('saver');

    try {
      await thunk();
      timer();
      
      this.requests -= 1;

      if (this.requests === 0 && status === SYNCING)
        dispatch(changeAppStatus(ONLINE));

      this.dispatchEvent(CYCLE_EVENT);

    } catch (error) {
      timer();
      logger.error('saver', error);
      attempts += 1;

      if (attempts < 5)
        return setTimeout(() => this.next(thunk, attempts), SEC_IN_MS);

      note('fatality', {
        type: 'notification',
        method: 'error',
        title: 'Критическая ошибка',
        content: error?.message,
        btn: NOTE_BUTTON,
      });

      setTimeout(() => this.next(thunk, attempts), FIVE_SEC_IN_MS);
    }
  }

  async addAction(lastAction) {
    const thunk = reduceThunk(lastAction);
    if (!thunk) return;

    this.requests += 1;
    this.next(thunk);
  }
}

export const getSaver = () => window.saver || (window.saver = new Saver());
