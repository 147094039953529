import { Big } from 'shared/big';
import { isParsable } from './isParsable';

export const round = (
  value?: string | number | Big | null,
  scale: number = 2,
) => {
  if (value instanceof Big) return value
    .toScale(scale)
    .toNumber();
  if (!isParsable(value)) return 0;
  const exp = 10 ** scale;
  return Math.round((value as any) * exp) / exp;
};
