import { io } from 'socket.io-client';

import { REQ_TIMEOUT, SOCKET_PATH, SOCKET_URL } from './consts';
import { buildExpectMethod, buildMethod } from './method';

export const request = {
  ...buildMethod('get'),
  ...buildMethod('post'),
  ...buildMethod('put'),
  ...buildMethod('patch'),
  ...buildMethod('delete'),
} as const;

export const requestExpect = {
  ...buildExpectMethod('get'),
  ...buildExpectMethod('post'),
  ...buildExpectMethod('put'),
  ...buildExpectMethod('patch'),
  ...buildExpectMethod('delete'),
} as const;

export const socket = io(SOCKET_URL, {
  autoConnect: false,
  rememberUpgrade: true,
  transports: ['websocket'],
  withCredentials: true,
  path: SOCKET_PATH,
  timeout: REQ_TIMEOUT,
  ackTimeout: REQ_TIMEOUT,
});
