import {
  DEAL_ADD_NEW_OUTLAY,
  DEAL_CHANGE_SELECTED,
  DEAL_COPY_OUTLAY,
  DEAL_DELETE_OUTLAY,
  DEAL_EDIT_DATA,
  DEAL_EDIT_OUTLAY,
  DEAL_FILL_LIST,
  DEAL_OUTLAY_CHANGE_ID,
  DEAL_SET_CLIENT_INFO,
} from '../constants';

export const fillDealList = (data) => ({
  type: DEAL_FILL_LIST,
  payload: { data },
});

export const editDeal = (data, callback) => ({
  type: DEAL_EDIT_DATA,
  payload: { data },
  callback: callback,
});

export const changeSelectedDeal = (data) => ({
  type: DEAL_CHANGE_SELECTED,
  payload: { data },
});

export const addNewOutlayToDeal = (data, isMasterAct = false) => ({
  type: DEAL_ADD_NEW_OUTLAY,
  payload: { data, isMasterAct },
});

export const copyOutlayToDeal = (data) => ({
  type: DEAL_COPY_OUTLAY,
  payload: { data },
});

export const deleteOutlayFromDeal = (outlayId) => ({
  type: DEAL_DELETE_OUTLAY,
  payload: { outlayId },
});

export const editOutlayFromDeal = (outlayId, data, type, doNotUpdate) => ({
  type: DEAL_EDIT_OUTLAY,
  payload: { outlayId, data, type },
  doNotUpdate,
});

export const changeOutlayId = (prevId, newId, outlay) => ({
  type: DEAL_OUTLAY_CHANGE_ID,
  payload: { prevId, newId, outlay },
});

export const setClientInfo = (dealId, data) => ({
  type: DEAL_SET_CLIENT_INFO,
  payload: { dealId, data },
});
