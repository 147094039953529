export type MethodColorDict = typeof METHOD_COLOR_DICT;
export type MethodColorKey = keyof MethodColorDict;
export type MethodColorValue = MethodColorDict[MethodColorKey];
export const METHOD_COLOR_DICT = {
  debug: '#595959',
  log: '#1677ff',
  warn: '#fa8c16',
  error: '#f5222d',
  group: '#52c41a',
} as const;
