import { pick, round } from 'shared/utils';

import { roundNumber } from './utils';

const KEYS = [
  'id',
  'uuid',
  'isTurnedOff',
  'priceCalculated',
  'priceManual',
  'quantityCalculated',
  'quantityManual',
  'sumCalculated',
  'discountCalculated',
  'masterPriceCalculated',
  'masterWageCalculated',
  'shiftPriceCalculated',
  'shiftWageCalculated',
  'coefficientCalculated',
  'bonusCalculated',
  'technologyDisclaimerCalculated',
  'actQuantities',
  'actQuantityTotal',
  'actSums',
  'actSumTotal',
] as const;

export const workSerializer = (work: { [key: string]: any }) => pick(work, KEYS, (value, key) => {
  if (key === 'actQuantities' || key === 'actSums')
    return value.map((item: any) => round(item));
  return roundNumber(value, key);
});
