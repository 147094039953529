import { arrayMoveMutable } from 'array-move';

import {
  OUTLAY_ADD_ROOM,
  // OUTLAY_CHANGE_POSITION_ORDER,
  OUTLAY_CHANGE_ROOM_ORDER,
  OUTLAY_CHANGE_SELECTED,
  OUTLAY_CUSTOM_POSITION_CHANGE_NAME,
  OUTLAY_DELETE_ROOM,
  OUTLAY_EDIT_SELECTED_OUTLAY,
  OUTLAY_POSITION_CHANGE_ACT_NUMBER,
  OUTLAY_POSITION_CHANGE_PRICE_MANUAL,
  OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL,
  OUTLAY_POSITION_CHANGE_UNIT,
  OUTLAY_POSITION_TURN,
  OUTLAY_RECALCULATE_VALUES,
  OUTLAY_ROOM_ADD_POSITIONS,
  OUTLAY_ROOM_CHANGE_BATHROOM_STATUS,
  OUTLAY_ROOM_CHANGE_HEIGHT,
  OUTLAY_ROOM_CHANGE_LENGTH,
  OUTLAY_ROOM_CHANGE_NAME,
  OUTLAY_ROOM_CHANGE_OPENINGS,
  OUTLAY_ROOM_CHANGE_PARAMS,
  OUTLAY_ROOM_CHANGE_WIDTH,
  OUTLAY_ROOM_DELETE_POSITION,
  OUTLAY_EDIT_PROMOTION,
  OUTLAY_CHANGE_ACT_NUMBER,
  OUTLAY_EDIT_SUB_ACT,
  OUTLAY_CREATE_SUB_ACT,
  OUTLAY_ADD_PROMOTION,
  OUTLAY_REMOVE_PROMOTION,
  OUTLAY_ROOM_CHANGE_TYPE,
  OUTLAY_POSITION_CHANGE_ACT_QUANTITY,
  OUTLAY_SET_PARENT_OUTLAY,
  OUTLAY_SET_USER_TEMPLATES,
  OUTLAY_ADD_USER_TEMPLATE,
  OUTLAY_DELETE_USER_TEMPLATE,
  OUTLAY_ADD_DOC,
  OUTLAY_UPDATE_DOC,
  OUTLAY_DELETE_DOC,
} from '../constants';
import { getMaxAct, pullBy } from 'shared/utils';

const initialState = {
  actNumber: -2,
  selectedOutlay: null,
  parentOutlay: null,
  userTemplates: [],
};

export const outlayReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case OUTLAY_CHANGE_SELECTED:
      return {
        ...state,
        selectedOutlay: payload.data,
        parentOutlay: null,
      };
    case OUTLAY_ADD_ROOM:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: [...state.selectedOutlay.rooms, payload.data],
        },
      };
    case OUTLAY_DELETE_ROOM: {
      const [rooms, roomsDeleted] = pullBy(
        state.selectedOutlay.rooms,
        (room) => room.id !== payload.roomId && room.uuid !== payload.roomId
      );
      const roomDeleted = roomsDeleted[0];
      if (roomDeleted)
        rooms.filter((room) => room.order > roomDeleted.order).forEach((room) => room.order -= 1);

      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms,
        },
      };
    }
    case OUTLAY_ROOM_ADD_POSITIONS:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              roomPositions: [...room.roomPositions, ...payload.roomPositions].sort((a, b) => {
                const aRow = a.position?.excelRow || 0;
                const bRow = b.position?.excelRow || 0;
                return aRow - bRow;
              }),
            };
          }),
        },
      };
    case OUTLAY_ADD_PROMOTION:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          outlayPromotions: [...state.selectedOutlay.outlayPromotions, payload.promotion],
        },
      };
    case OUTLAY_REMOVE_PROMOTION: {
      const [outlayPromotions] = pullBy(
        state.selectedOutlay.outlayPromotions,
        (promo) => promo.id !== payload.promotionId && promo.id !== payload.promotionId
      );

      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          outlayPromotions,
        },
      };
    }
    case OUTLAY_EDIT_PROMOTION:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          outlayPromotions: state.selectedOutlay.outlayPromotions.map((promo) => {
            if (promo.id !== payload.promotionId && promo.uuid !== payload.promotionId) return promo;
            return { ...promo, ...payload.data };
          }),
        },
      };
    case OUTLAY_ROOM_DELETE_POSITION: {
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;

            const [roomPositions] = pullBy(
              room.roomPositions,
              (value) => value.id !== payload.id
            );
            return {
              ...room,
              roomPositions,
              // roomPositionsDeleted: [...(room.roomPositionsDeleted || []), ...roomPositionsDeleted],
            };
          }),
        },
      };
    }
    case OUTLAY_POSITION_CHANGE_ACT_QUANTITY: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return {
            ...room,
            roomPositions: room.roomPositions.map((work) => {
              if (work.id !== payload.roomPositionId && work.uuid !== payload.roomPositionId) return work;
              return {
                ...work,
                actQuantities: payload.actQuantities,
              };
            }),
          };
        }),
      }
    }
    case OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              roomPositions: room.roomPositions.map((work) => {
                if (work.id !== payload.roomPositionId && work.uuid !== payload.roomPositionId) return work;
                return { ...work, quantityManual: payload.quantity };
              }),
            };
          }),
        },
      };
    case OUTLAY_POSITION_CHANGE_PRICE_MANUAL:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              roomPositions: room.roomPositions.map((work) => {
                if (work.id !== payload.roomPositionId && work.uuid !== payload.roomPositionId) return work;
                return { ...work, priceManual: payload.price };
              }),
            };
          }),
        },
      };
    case OUTLAY_POSITION_TURN:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId) return room;
            return {
              ...room,
              roomPositions: room.roomPositions.map((work) => {
                if (work.id !== payload.roomPositionId && work.uuid !== payload.roomPositionId) return work;
                return { ...work, isTurnedOff: !!payload.value };
              }),
            };
          }),
        },
      };
    case OUTLAY_ROOM_CHANGE_NAME:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              name: payload.name ?? null,
              roomTypeId: payload.name ? null : room.roomTypeId,
              roomType: payload.name ? null : room.roomType,
            };
          }),
        },
      };
    case OUTLAY_ROOM_CHANGE_TYPE:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              name: payload.roomType ? null : room.name,
              roomTypeId: payload.roomType?.id ?? null,
              roomType: payload.roomType ?? null,
              isBathroom: payload.roomType?.isBathroom ?? room.isBathroom,
            };
          }),
        },
      };
    case OUTLAY_ROOM_CHANGE_WIDTH: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return { ...room, width: payload.data };
        }),
      },
    };
    case OUTLAY_ROOM_CHANGE_PARAMS: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return { ...room, ...payload.data, id: room.id, uuid: room.uuid };
        }),
      },
    };
    case OUTLAY_ROOM_CHANGE_HEIGHT: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return { ...room, height: payload.data };
        }),
      },
    };
    case OUTLAY_ROOM_CHANGE_OPENINGS: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return { ...room, openings: payload.data };
        }),
      },
    };
    case OUTLAY_ROOM_CHANGE_LENGTH: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return { ...room, length: payload.data };
        }),
      },
    };
    case OUTLAY_RECALCULATE_VALUES: {
      if (!state.selectedOutlay) return state;
      if (state.selectedOutlay.id !== payload.id && state.selectedOutlay.uuid !== payload.id) return state;

      const rooms = state.selectedOutlay.rooms?.map((room, idx) => {
        const roomUpdate = payload.rooms[idx];

        const roomPositions = room.roomPositions?.map((work, ydx) => {
          const workUpdate = roomUpdate.roomPositions[ydx];
          return { ...work, ...workUpdate, id: work.id, uuid: work.uuid };
        }) || [];

        return {
          ...room,
          ...roomUpdate,
          id: room.id,
          uuid: room.uuid,
          roomPositions,
        };
      }) || [];

      const subActs = state.selectedOutlay.subActs?.map((act, idx) => {
        const actUpdate = payload.subActs[idx];
        return { ...act, ...actUpdate };
      }) || [];

      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          ...payload,
          id: state.selectedOutlay.id,
          rooms,
          subActs,
        },
      };
    }
    case OUTLAY_ROOM_CHANGE_BATHROOM_STATUS:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              isBathroom: payload.value,
            };
          }),
        },
      };
    case OUTLAY_EDIT_SELECTED_OUTLAY:
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          ...payload.data,
        },
      };
    case OUTLAY_CHANGE_ROOM_ORDER: {
      arrayMoveMutable(
        state.selectedOutlay.rooms.sort((a, b) => a.order - b.order),
        payload.sourceIndex,
        payload.destinationIndex,
      );

      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room, idx) => ({
            ...room,
            order: idx + 1,
          })),
        },
      };
    }
    // case OUTLAY_CHANGE_POSITION_ORDER: {
    //   const workIds = payload.positions
    //     .map((flp) => ({ id: flp.item.id, order: flp.item.order }))
    //     .sort((a, b) => a.order - b.order);

    //   const reorderedFLP = arrayMoveMutable(workIds, payload.sourceIndex, payload.destinationIndex).map(
    //     (flp, idx) => ({ ...flp, order: idx + 1 })
    //   );

    //   const mapReorderedPositions = (pos) => {
    //     const foundPos = reorderedFLP.find((flp) => flp.id === pos.id);
    //     if (!foundPos) return pos;
    //     return {
    //       ...pos,
    //       order: foundPos.order,
    //     };
    //   };

    //   const rooms = state.selectedOutlay.rooms.map((room) => {
    //     if (room.id !== payload.room.id && room.uuid !== payload.room.id) return room;

    //     return {
    //       ...room,
    //       roomPositions: room.roomPositions.map(mapReorderedPositions),
    //     };
    //   });

    //   return {
    //     ...state,
    //     selectedOutlay: {
    //       ...state.selectedOutlay,
    //       rooms: rooms,
    //     },
    //   };
    // }
    case OUTLAY_CUSTOM_POSITION_CHANGE_NAME: {
      const rooms = state.selectedOutlay.rooms.map((room) => {
        if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;

        const roomPositions = room.roomPositions.map((work) => {
          if (!work.custom) return work;
          if (work.id !== payload.positionId && work.uuid !== payload.positionId) return work;

          return {
            ...work,
            customName: payload.name,
          };
        });
        return {
          ...room,
          roomPositions,
        };
      });
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms,
        },
      };
    }
    case OUTLAY_POSITION_CHANGE_ACT_NUMBER: {
      const actNumber = +payload.actNumber;
      const maxActNumber = getMaxAct(state.selectedOutlay.subActs);

      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          rooms: state.selectedOutlay.rooms.map((room) => {
            if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
            return {
              ...room,
              roomPositions: room.roomPositions.map((work) => {
                if (work.id !== payload.roomPositionId && work.uuid !== payload.roomPositionId) return work;
                if (actNumber !== -2) return { ...work, actNumber };
                return { ...work, actNumber, actQuantities: Array(maxActNumber).fill(0), actSums: Array(maxActNumber).fill(0) };
              }),
            };
          }),
        },
      };
    }
    case OUTLAY_POSITION_CHANGE_UNIT: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        rooms: state.selectedOutlay.rooms.map((room) => {
          if (room.id !== payload.roomId && room.uuid !== payload.roomId) return room;
          return {
            ...room,
            roomPositions: room.roomPositions.map((work) => {
              if (work.id !== payload.positionId && work.uuid !== payload.positionId) return work;
              return { ...work, customUnit: payload.unit };
            }),
          };
        }),
      },
    };
    case OUTLAY_SET_PARENT_OUTLAY: {
      return {
        ...state,
        parentOutlay: payload.outlay,
      };
    }
    /* SUB_ACT / START */
    case OUTLAY_CHANGE_ACT_NUMBER: {
      const isOutlayNull = state.selectedOutlay === null;
      const isOutlay = state.selectedOutlay?.type === 'outlay';
      if (isOutlayNull || isOutlay) return { ...state, actNumber: -2 };

      const actNumber =
        isNaN(payload.actNumber) || payload.actNumber === null ? -2 : parseInt(payload.actNumber);
      return { ...state, actNumber };
    }
    case OUTLAY_EDIT_SUB_ACT: {
      const isOutlay = state.selectedOutlay.type === 'outlay';
      const actNumber = isNaN(payload.actNumber) ? state.actNumber : parseInt(payload.actNumber);
      if (isOutlay || actNumber === -2) return { ...state, actNumber: -2 };

      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          subActs: state.selectedOutlay.subActs
            .map((act) => {
              if (parseInt(act.actNumber) !== actNumber) return act;
              return { ...act, ...payload.data };
            })
            .sort((a, b) => a.actNumber - b.actNumber),
        },
      };
    }
    case OUTLAY_CREATE_SUB_ACT: {
      if (state.selectedOutlay.type !== 'act') return state;
      const { subAct } = payload;
      return {
        ...state,
        selectedOutlay: {
          ...state.selectedOutlay,
          maxActNumber: subAct.actNumber,
          subActs: [...state.selectedOutlay.subActs, subAct],
          rooms: state.selectedOutlay.rooms.map((room) => ({
            ...room,
            roomPositions: room.roomPositions.map((work) => ({
              ...work,
              actQuantities: [...work.actQuantities, 0],
              actSums: [...work.actSums, 0],
            })),
          })),
        },
      };
    }
    /* SUB_ACT / END */
    /* USER_TEMPLATE / START */
    case OUTLAY_SET_USER_TEMPLATES: return {
      ...state,
      userTemplates: payload,
    };
    case OUTLAY_ADD_USER_TEMPLATE: return {
      ...state,
      userTemplates: [...state.userTemplates, payload.userTemplate],
    };
    case OUTLAY_DELETE_USER_TEMPLATE: return {
      ...state,
      userTemplates: state.userTemplates.filter(({ id, uuid }) => {
        return id !== payload.userTemplateId && uuid !== payload.userTemplateId;
      }),
    };
    /* USER_TEMPLATE / END */
    /* DOC / START */
    case OUTLAY_ADD_DOC: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        docs: [...state.selectedOutlay.docs, payload.doc],
      },
    };
    case OUTLAY_UPDATE_DOC: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        docs: state.selectedOutlay.docs.map((doc) => {
          if (doc.id !== payload.doc.id) return doc;
          return { ...doc, ...payload.doc };
        }),
      },
    };
    case OUTLAY_DELETE_DOC: return {
      ...state,
      selectedOutlay: {
        ...state.selectedOutlay,
        docs: state.selectedOutlay.docs.filter((doc) => doc.id !== payload.docId),
      },
    };
    /* DOC / END */
    default:
      return state;
  }
};
