import { isParsable, round } from 'shared/utils';
import { add, sub, mul, div, gtz, ltz, lt, eqz } from 'shared/big';

import { WORK_PROPS } from '../const';

import Calc from './Calc';
import { rectifyRooms } from './rectifyRooms';
import { rectifyResult } from './rectifyResult';
import { findByIdOrUuid } from './findByIdOrUuid';

class CalcOutlay extends Calc {
  _updatePosValue(roomPositionId, roomId, prop, newVal) {
    const room = this.rooms.find(findByIdOrUuid(roomId));
    const roomPosition = room.roomPositions.find(findByIdOrUuid(roomPositionId));

    if (prop === 'isTurnedOff') {
      roomPosition[prop] = newVal;
      // if (roomPosition.custom) {
      //   if (newVal === true) {
      //     roomPosition.sumCalculated = 0;
      //   } else if (newVal === false) {
      //     this.calcPos(roomPosition, 'sum', true);
      //   }
      // } else {
        for (const workProp of WORK_PROPS)
          this.updatePositionChildren(roomPosition, roomId, workProp);
      // }
    } else {
      roomPosition[prop + 'Manual'] = newVal;
      if (roomPosition.custom) {
        for (const workProp of WORK_PROPS)
          this.calcWork(roomPosition, workProp, true);

      } else {
        this.calcWork(roomPosition, prop, true);
      }
      
      this.updatePositionChildren(roomPosition, roomId, prop);
    }

    this.calculateRoomSum(room);
  }

  updateOutlayValue(prop, newVal) {
    if (prop) this.outlay[prop] = isParsable(newVal) ? round(newVal) : newVal;
    if (prop === 'VAT') {
      this.outlay.VAT = newVal;
      this.isVAT = newVal;
    }

    this.updateOutlayChildren(prop);

    for (const room of this.rooms) {
      this.calculateRoomSum(room);
    }

    this.finishCalculations();
  }

  checkTechDisclaimer() {
    this.technologyDisclaimer = this.rooms.some(({ roomPositions }) =>
      roomPositions.some((work) => work.technologyDisclaimerCalculated)
    );
  }

  positionSumReducer(positions) {
    return positions.reduce(
      (total, work) => (work.isTurnedOff ? total : add(total, work.sumCalculated)),
      0
    );
  }

  calcSum() {
    this.sum = 0;
    this.fullSum = 0;
    this.discountableSum = 0;
    this.discountValue = 0;
    this.workTypeSums = { '0': 0 };
    for (const type of this.workTypes) {
      this.workTypeSums[type.id.toString()] = 0;
    }

    for (let idx = 0; idx < this.rooms.length; idx++) {
      const room = this.rooms[idx];
      for (let ydx = 0; ydx < room.roomPositions.length; ydx++) {
        const work = room.roomPositions[ydx];
        if (work.isTurnedOff) continue;

        const sum = work.sumCalculated;
        this.sum = add(this.sum, sum);
        const workTypeKey = work.position?.workTypeId?.toString() || '0';
        this.workTypeSums[workTypeKey] = add(this.workTypeSums[workTypeKey], sum);
        const fullSum = work.fullSumCalculated
        this.fullSum = add(this.fullSum, fullSum);
        this.discountValue = add(this.discountValue, work.discountSumCalculated);

        if (!work.hasDiscount) continue;
        this.discountableSum = add(this.discountableSum, fullSum);
      }
    }

    this.discount = round(this.outlay.discount);

    if (isParsable(this.maxDiscountOverride)) {
      this.maxDiscount = round(this.maxDiscountOverride);
    } else {
      this.maxDiscount = 0;
      for (const constraint of this.discountConstraints) {
        if (lt(this.discountableSum, constraint.sum)) continue;
        this.maxDiscount = round(constraint.discount);
      }
    }

    // const maxDiscountMultiplier = sub(100, this.maxDiscount).div(100);
    this.maxDiscountValue = mul(this.discountableSum, this.maxDiscount).div(100).toScale(2);
    // for (let idx = 0; idx < this.rooms.length; idx++) {
    //   const room = this.rooms[idx];
    //   for (let ydx = 0; ydx < room.roomPositions.length; ydx++) {
    //     const work = room.roomPositions[ydx];
    //     if (!work.hasDiscount) continue;
    //     const mdWork = { ...work };
    //     if (!work.custom) {
    //       const maxDiscountPrice = parse(mdWork.position.priceFormula, { deal: this.deal, outlay: this.outlay, room, work: { ...mdWork, discountCalculated: maxDiscountMultiplier } })().toScale(2);
    //       const maxDiscountSum = parse(mdWork.position.sumFormula, { deal: this.deal, outlay: this.outlay, room, work: { ...mdWork, priceCalculated: maxDiscountPrice } })().toScale(2);
    //       const maxDiscountValue = parse(mdWork.position.discountSumFormula, { deal: this.deal, outlay: this.outlay, room, work: { ...mdWork, sumCalculated: maxDiscountSum } })();
    //       this.maxDiscountValue = add(this.maxDiscountValue, maxDiscountValue);
    //     } else {
    //       const maxDiscountSum = parse(mdWork.position.sumFormula, { deal: this.deal, outlay: this.outlay, room, work: { ...mdWork, discountCalculated: maxDiscountMultiplier } })().toScale(2);
    //       const maxDiscountValue = parse(mdWork.position.discountSumFormula, { deal: this.deal, outlay: this.outlay, room, work: { ...mdWork, sumCalculated: maxDiscountSum } })();
    //       this.maxDiscountValue = add(this.maxDiscountValue, maxDiscountValue);
    //     }
    //     // const maxDiscountSum = mul(work.fullPriceCalculated, maxDiscountMultiplier).toScale(2).mul(work.quantityCalculated).toScale(2);
    //     // this.maxDiscountValue = sub(work.fullSumCalculated, maxDiscountSum).toScale(2).add(this.maxDiscountValue);
    //     // this.maxDiscountValue = div(this.maxDiscount, 100).mul(work.fullSumCalculated).toScale(2).add(this.maxDiscountValue);
    //   }
    // }

    this.promotionSum = 0;
    for (const promo of this.outlay.outlayPromotions) {
      const promoSum = round(promo.promotion.value || promo.valueManual);

      if (promo.promotion.rid === 4)
        this.promotionSumNP = add(this.promotionSumNP ?? 0, promoSum);
      if (promo.promotion.rid === 9)
        this.promotionSumCleaning = add(this.promotionSumCleaning ?? 0, promoSum);

      this.promotionSum = add(this.promotionSum, promoSum);
    }
    const isDiscountableSumNonZero = gtz(this.discountableSum);
    this.promotionPercent = isDiscountableSumNonZero
      ? div(this.promotionSum, this.discountableSum).mul(100).toScale(2)
      // ? div(this.promotionSum, this.discountableSum).mul(100)
      : 0;

    // this.totalDiscountValue = add(this.discountValue, this.promotionSum);
    this.totalDiscount = add(this.discount, this.promotionPercent);
    this.totalDiscountValue = mul(this.discountableSum, this.totalDiscount).div(100).toScale(2);
    // this.totalDiscount = isDiscountableSumNonZero ? div(this.totalDiscountValue, this.discountableSum).mul(100).toScale(2) : 0;

    
    // this.maxDiscountValue = mul(this.discountableSum, this.maxDiscount).div(100).toScale(2);

    // this.remainingDiscountValue = sub(this.maxDiscountValue, this.totalDiscountValue);
    this.remainingDiscount = sub(this.maxDiscount, this.totalDiscount);
    this.remainingDiscountValue = mul(this.discountableSum, this.remainingDiscount).div(100).toScale(2);
    // this.remainingDiscount = isDiscountableSumNonZero ? div(this.remainingDiscountValue, this.discountableSum).mul(100).toScale(2) : 0;
    this.isDiscountValid = !ltz(this.remainingDiscountValue);
    if (!this.isDiscountValid) this.isFinal = false;
    this.showDiscount = gtz(this.discount);

    // this.areWorkTypeConstraintsMet = true;
    this.workTypeConstraintViolations = [];
    for (const type of this.workTypes) {
      const workTypeSum = round(this.workTypeSums[type.id.toString()]);
      if (type.rid === 7) this.categorySumNP = workTypeSum;
      if (type.rid === 8) this.categorySumSpecial = workTypeSum;
      if (type.rid === 9) this.categorySumCleaning = workTypeSum;

      if (type.minimalSum === null) continue;
      if (eqz(workTypeSum)) continue;

      const minimalSum = round(type.minimalSum);
      if (!lt(workTypeSum, minimalSum)) continue;

      // this.areWorkTypeConstraintsMet = false;
      this.workTypeConstraintViolations.push(type.name);
    }
  }

  _finishCalculations() {
    this.calcSum();
    this.checkTechDisclaimer();

    this.rectifiedRooms = rectifyRooms(this.rooms, this.workTypes, this.materialTypes);
    this.result = rectifyResult(this, this.rooms);
  }
}

export default CalcOutlay;
