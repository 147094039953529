// import { Spin } from 'antd';

import { Loading3QuartersOutlined } from '@ant-design/icons';
import styles from './TotalSpinner.module.scss';

export const TotalSpinner = () => (
  <div className={styles.Spinner}>
    <h1>
      <span>REMONT</span>
      <span>.</span>
      <span>IO</span>
    </h1>
    <h2>
      <span>Смета</span>
      <span>
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45"/>
      </svg>
      </span>
    </h2>
  </div>
);
