/* Constants */
export type Access = (typeof ACCESS)[keyof typeof ACCESS];
export const ACCESS = {
  regular: 'regular',
  elevated: 'elevated',
  privileged: 'privileged',
} as const;

export type Role = (typeof ROLES)[keyof typeof ROLES];
export const ROLES = {
  estimator: 'estimator',
  foreman: 'foreman',
  admin: 'admin',
  chiefEstimator: 'chiefEstimator',
  chiefForeman: 'chiefForeman',
  overseer: 'overseer',
} as const;

export const ALL_ROLES: readonly Role[] = Object.values(ROLES);

export const ELEVATED_ROLES = [
  ROLES.overseer,
] as const;

export const PRIVILEGED_ROLES = [
  ROLES.admin,
  ROLES.chiefEstimator,
  ROLES.chiefForeman,
] as const;

export const ACT_ROLES = [
  ROLES.foreman,
  ROLES.overseer,
  ROLES.chiefForeman,
] as const;

export type RoleName = (typeof ROLE_NAME_DICT)[keyof typeof ROLE_NAME_DICT];
export const ROLE_NAME_DICT = {
  [ROLES.estimator]: 'Сметчик',
  [ROLES.foreman]: 'Прораб',
  [ROLES.admin]: 'Админ',
  [ROLES.chiefEstimator]: 'НСДО',
  [ROLES.chiefForeman]: 'РПД',
  [ROLES.overseer]: 'НУ',
} as const;

export const NAME_ROLE_DICT = {
  [ROLE_NAME_DICT.estimator]: ROLES.estimator,
  [ROLE_NAME_DICT.foreman]: ROLES.foreman,
  [ROLE_NAME_DICT.admin]: ROLES.admin,
  [ROLE_NAME_DICT.chiefEstimator]: ROLES.chiefEstimator,
  [ROLE_NAME_DICT.chiefForeman]: ROLES.chiefForeman,
  [ROLE_NAME_DICT.overseer]: ROLES.overseer,
};

/* Logic */
export type UserWithType = { userType?: { name?: string }, role: string };

export const getUserRole = (user: UserWithType): Role => {
  const role = user?.userType?.name || user?.role as RoleName | undefined;
  if (ALL_ROLES.includes(role)) return role;
  return !role || !NAME_ROLE_DICT.hasOwnProperty(role) ? ROLES.estimator : NAME_ROLE_DICT[role as RoleName];
};

export const getUserAccess = (user: UserWithType): Access => {
  const role = getUserRole(user);
  if (PRIVILEGED_ROLES.includes(role)) return ACCESS.privileged;
  if (ELEVATED_ROLES.includes(role)) return ACCESS.elevated;
  return ACCESS.regular;
};

export const isUserParent = (user: UserWithType): boolean => {
  const access = getUserAccess(user);
  return access === ACCESS.privileged || access === ACCESS.elevated;
};

export const isUserAct = (user: UserWithType): boolean => {
  const role = getUserRole(user);
  return ACT_ROLES.includes(role);
};
