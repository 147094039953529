import { stringify as objectToQuery } from 'qs';

import note from 'shared/note';
import { removeCurUser, setAuthStatus } from 'shared/storage';

import { BASE_URL, REQ_TIMEOUT, MethodKey, MethodValue, METHOD_DICT, LOGIN_ERROR } from './consts';
import { FetchError } from './errors';

export type FetchMethodOptions = Omit<RequestInit, 'method' | 'headers' | 'body' | 'signal'> & {
  baseUrl?: string,
  requestTimeout?: number,
  credentials?: 'omit' | 'same-origin' | 'include',
  headers?: { [key: string]: string },
};

export type FetchMethodMixin<K extends MethodKey, R> = {
  [key in K]: (
    path?: string,
    data?: { [key: string]: any },
    options?: FetchMethodOptions,
  ) => Promise<R>;
};

export const getHeaders = (headers: { [key: string]: string } = {}, isFormData = false) => {
  if (isFormData) delete headers['Content-Type'];
  else headers['Content-Type'] ??= 'application/json';
  return headers;
};

export const buildMethod = <K extends MethodKey>(methodKey: K) => ({
  [methodKey]: async (path?: string, data?: { [key: string]: any }, options: FetchMethodOptions = {}) => {
    const method = METHOD_DICT[methodKey] as MethodValue;
    const isBodiless = method === METHOD_DICT.get || method === METHOD_DICT.delete;
    const isFormData = data instanceof FormData;

    const params = (!isBodiless || !data) ? '' : `?${objectToQuery(data)}`;
    const fullUrl = `${options.baseUrl || BASE_URL}${path}${params}`;

    const controller = new AbortController();
    const fullOptions: RequestInit = options;
    fullOptions.method = method;
    fullOptions.credentials ??= 'include';
    fullOptions.signal = controller.signal;
    fullOptions.headers = getHeaders(options.headers, isFormData);
    fullOptions.body = (isBodiless || !data)
      ? undefined
      : isFormData || data instanceof Blob
        ? data
        : JSON.stringify(data);

    const timeoutId = setTimeout(() => controller.abort(), options.requestTimeout ?? REQ_TIMEOUT);

    try {
      const response = await fetch(fullUrl, fullOptions);
      clearTimeout(timeoutId);

      const data = ((await response.json()) || {}) as { [key: string]: any };
      if (response.status < 400) return data;
      if (!data?.error) throw new FetchError();

      const error = data.error;
      if (!error?.code) throw new FetchError();
      if (error.code !== LOGIN_ERROR) throw new FetchError(error);

      removeCurUser();
      setAuthStatus(false);
      await window.router.navigate('/', { replace: true });
      throw new FetchError(error);

    } catch (error: any) {
      console.error(error);
      if (error?.name !== 'AbortError') clearTimeout(timeoutId);
      else throw new FetchError({ message: 'Сбой или отсутствие подключения к сети. Проверьте соединение и/или повторите позже.' });
      if (!error?.code || !error?.message) throw new FetchError();
      throw new FetchError(error);
    }
  },
}) as FetchMethodMixin<K, { [key: string]: any }>;

export const buildExpectMethod = <K extends MethodKey>(methodKey: K) => {
  const method = buildMethod(methodKey)[methodKey];
  return {
    [methodKey]: async (path?: string, data?: { [key: string]: any }, options?: FetchMethodOptions) => {
      try {
        const result = await method(path, data, options);
        return result;

      } catch (error: any) {
        if (error?.code && error?.message) note(error.code, { content: error.message });
        return undefined;
      }
    },
  } as FetchMethodMixin<K, { [key: string]: any } | undefined>;
};
