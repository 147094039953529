import { IDBPDatabase, StoreNames, StoreValue } from 'idb';
import { logger } from 'shared/utils';

import { LOAD_EVENT, NO_IDB_PROMISE, UNLOAD_EVENT } from '../consts';

import { initStaticIdb } from './init';
import { StaticIdbSchema } from './types';

export class StaticIdbController extends EventTarget {
  idb: IDBPDatabase<StaticIdbSchema> | null = null;
  promiseCache: { [key in StoreNames<StaticIdbSchema>]?: Promise<StoreValue<StaticIdbSchema, key>[]> } = {};

  async load() {
    if (this.idb !== null) return;

    this.unload();

    try {
      this.idb = await initStaticIdb();
      this.dispatchEvent(LOAD_EVENT);

    } catch (error) {
      logger.error('static idb', error);
      this.idb = null;
    }
  }

  unload() {
    if (this.idb === null) return;
    this.dispatchEvent(UNLOAD_EVENT);
    this.idb.close();
    this.idb = null;
    this.promiseCache = {};
  }

  getAll<N extends StoreNames<StaticIdbSchema>>(storeName: N): Promise<StaticIdbSchema[N]["value"][]> {
    if (!this.idb) return NO_IDB_PROMISE;
    
    if (this.promiseCache.hasOwnProperty(storeName))
      return this.promiseCache[storeName]!;

    const transaction = this.idb.transaction(storeName);
    const promise = transaction.store.getAll();
    this.promiseCache[storeName] = promise as any;
    return promise;
  }
}
