type IDBOpenDBRequestEvent = EventTarget & { result: IDBDatabase };

if (!window.IDBFactory.prototype.databases) {
  const KEY = 'IDB_DATABASES_POLYFILL';

  const getIdbInfoEntries = (): IDBDatabaseInfo[] => {
    const serialized = localStorage.getItem(KEY);
    return serialized ? JSON.parse(serialized) : [];
  };

  const addIdbInfoEntry = (idbInfoEntry: IDBDatabaseInfo) => {
    const result = getIdbInfoEntries()
      .filter(({ name }) => name !== idbInfoEntry.name);
    result.push(idbInfoEntry);

    localStorage.setItem(KEY, JSON.stringify(result));
  };

  const deleteIdbInfoEntry = (idbName: string) => {
    const result = getIdbInfoEntries()
      .filter(({ name }) => name !== idbName);
    
    localStorage.setItem(KEY, JSON.stringify(result));
  };

  const boundOpen = window.indexedDB.open.bind(window.indexedDB);
  window.indexedDB.open = function open(name: string, version?: number): IDBOpenDBRequest {
    const request = boundOpen(name, version);

    request.addEventListener('success', (event) => addIdbInfoEntry({
      name: (event.target as IDBOpenDBRequestEvent).result.name,
      version: (event.target as IDBOpenDBRequestEvent).result.version,
    }));

    return request;
  };

  const boundDeleteDatabase = window.indexedDB.deleteDatabase.bind(window.indexedDB);
  window.indexedDB.deleteDatabase = function deleteDatabase(name: string): IDBOpenDBRequest {
    const request = boundDeleteDatabase(name);

    request.addEventListener('success', () => deleteIdbInfoEntry(name));

    return request;
  };

  window.IDBFactory.prototype.databases = function databases(): Promise<IDBDatabaseInfo[]> {
    return Promise.resolve(getIdbInfoEntries());
  };
}

export {};
