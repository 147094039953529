import { pick } from 'shared/utils';

import { roundNumber } from './utils';

const KEYS = [
  'id',
  'uuid',
  'actNumber',
  'sum',
  'fullSum',
  'technologyDisclaimer',
  'discount',
  'discountValue',
  'promotionSum',
  'promotionPercent',
  'totalDiscount',
  'totalDiscountValue',
  'remainingDiscount',
  'remainingDiscountValue',
  'isDiscountValid',
  'categorySumNP',
  'categorySumSpecial',
  'categorySumCleaning',
  'categorySumDoors',
  'masterWageSum',
  'masterWageSumNP',
  'masterWageSumSpecial',
  'masterWageSumCleaning',
  'masterWageSumDoors',
  'promotionSumNP',
  'promotionSumCleaning',
] as const;

export const actSerializer = (act: { [key: string]: any }) => pick(act, KEYS, roundNumber);
