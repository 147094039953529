import { isParsable } from 'shared/utils';
import { Big, Numeric } from './Big';

type BinaryOperation = (a: Numeric, b: Numeric) => Big;

export const add: BinaryOperation = (a, b) => Big.coerce(a).add(b);

export const sub: BinaryOperation = (a, b) => Big.coerce(a).sub(b);

export const mul: BinaryOperation = (a, b) => Big.coerce(a).mul(b);

export const div: BinaryOperation = (a, b) => Big.coerce(a).div(b);

type CompBinaryOperation = (a: Numeric, b: Numeric) => boolean;

export const eq: CompBinaryOperation = (a, b) => Big.coerce(a).eq(b);

export const gt: CompBinaryOperation = (a, b) => Big.coerce(a).gt(b);

export const gte: CompBinaryOperation = (a, b) => Big.coerce(a).gte(b);

export const lt: CompBinaryOperation = (a, b) => Big.coerce(a).lt(b);

export const lte: CompBinaryOperation = (a, b) => Big.coerce(a).lte(b);

type CompUnaryOperation = (value: Numeric) => boolean;

const bigZero = new Big(0);

export const eqz: CompUnaryOperation = (value) => {
  if (value instanceof Big) return value.eq(bigZero);
  return isParsable(value) ? (+value) === 0 : false;
};

export const gtz: CompUnaryOperation = (value) => {
  if (value instanceof Big) return value.gt(bigZero);
  return isParsable(value) ? (+value) > 0 : false;
};

export const ltz: CompUnaryOperation = (value) => {
  if (value instanceof Big) return value.lt(bigZero);
  return isParsable(value) ? (+value) < 0 : false;
};
