import { METHOD_COLOR_DICT, MethodColorKey } from './consts';
import { getNestingLevel } from './store';

export const getPrefixArgs = (method: MethodColorKey, prefix?: string | null) => getNestingLevel() > 0 || !prefix ? [] : [
  `%c[ ${prefix} ]`,
  `background: ${METHOD_COLOR_DICT[method]}; border-radius: 0.5em; color: white; font-weight: bold; padding: 2px 0.5em;`,
];

export const buildMethod = <M extends Exclude<MethodColorKey, 'group'>>(method: M) => ({
  [method]: (prefix?: string | null, ...args: any[]) => console[method](...getPrefixArgs(method, prefix), ...args),
}) as { [key in M]: (prefix?: string | null, ...args: any[]) => void };
