import { Big } from 'shared/big';
import { isParsable, round } from 'shared/utils';

export const cut = (
  value?: string | number | Big | null,
  scale: number = 2,
) => {
  const result = isParsable(value) ? round(value, scale) : 0;
  return result.toFixed(scale);
};
