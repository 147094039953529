import {
  WORK_FILTER_SET_SUB_TYPE_LIST,
  WORK_FILTER_SWITCH_FILTER_TYPE,
  WORK_FILTER_SET_SUB_TYPES,
  WORK_FILTER_SET_ONE_SUB_TYPE,
} from './constants';

const initialState = {
  subTypeList: [],
  type: 'room',
  subTypes: [],
};

export const workFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORK_FILTER_SET_SUB_TYPE_LIST: {
      const subTypeList = [...action.payload, { id: 0, name: 'Прочие работы' }];

      return {
        ...state,
        subTypeList,
        subTypes: subTypeList.map(({ id }) => id),
      };
    }
    case WORK_FILTER_SWITCH_FILTER_TYPE:
      return {
        ...state,
        type: action.payload,
        subTypes: state.subTypeList.map(({ id }) => id),
      };
    case WORK_FILTER_SET_SUB_TYPES: {
      const key = action.payload;
      const result = { ...state };

      if (!state.subTypes.includes(key)) result.subTypes = [...result.subTypes, key];
      else result.subTypes = result.subTypes.filter((type) => type !== key);

      return result;
    }
    case WORK_FILTER_SET_ONE_SUB_TYPE:
      return {
        ...state,
        subTypes: [action.payload],
      };
    default:
      return state;
  }
};
