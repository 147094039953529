export const LOCAL_VAR_RGX = /\$?[A-Z]{1,3}\$?[0-9]{1,3}/g;
export const LOCAL_VAR_COL_ROW_RGX = /[A-Z]{1,3}|[0-9]{1,3}/g;
export const GLOBAL_VAR_RGX = /[А-ЯЁ_]+/g;

export type OutlayVarMap = typeof OUTLAY_VAR_MAP;
export type OutlayVar = keyof OutlayVarMap;
export type OutlayProp = OutlayVarMap[OutlayVar];

export type ColPropMap = typeof COL_PROP_MAP;
export type ColLetter = keyof ColPropMap;
export type ColProp = ColPropMap[ColLetter];

export const OUTLAY_VAR_MAP = {
  ШИРИНА: 'width',
  ВЫСОТА: 'height',
  ДЛИНА: 'length',
  ПРОЕМЫ: 'openings',
  ПЕРИМЕТР: 'perimeter',
  ПЛОЩ_СТЕН: 'areaWalls',
  ПЛОЩ_ПОЛА: 'areaFloor',
  КОЛ_РОЗ: 'outletNumber',
  ОТКОСЫ: 'slope',
  САНУЗЕЛ: 'isBathroom',
  С_СМЕТ: 'discount',
  СКИДКА_ОБЩАЯ: 'discountValue',
  К_СМЕТ: 'coefficient',
  СУММ_СМЕТ: 'sum',
  К_ПОМЕЩ: 'coefficient',
  ПИРОГ: 'quantity',
  СУММ_ПЛОЩ: 'cumArea',
  СУММ_ПЕРИМ: 'cumPerim',
  УСЛОВИЯ_РАБОТЫ: 'workConditionTypeId',
  ДИЗАЙН_ПРОЕКТ: 'designProjectTypeId',
  К_ФОТ_МАСТЕР: 'masterWageCoefficient',
  К_ФОТ_НП_МАСТЕР: 'masterWageNPCoefficient',
  К_ФОТ_ВАХТА: 'shiftWageCoefficient',
  К_ФОТ_НП_ВАХТА: 'shiftWageNPCoefficient',
  М_СМП: 'specialMultiplier',
  К_ПРЕМИЯ: 'bonusCoefficient',
  ПРЕМИЯ_ТЕЛЕФОН: 'bonusPhone',
} as const;

export const ROOM_FORMULAIC_PROPS = [
  OUTLAY_VAR_MAP.ПЕРИМЕТР,
  OUTLAY_VAR_MAP.ПЛОЩ_ПОЛА,
  OUTLAY_VAR_MAP.ПЛОЩ_СТЕН,
  OUTLAY_VAR_MAP.К_ПОМЕЩ,
] as const;

export const ROOM_PROPS = [
  'ВЫСОТА',
  'ШИРИНА',
  'ДЛИНА',
  'ПРОЕМЫ',
  'КОЛ_РОЗ',
  'ОТКОСЫ',
  'К_ПОМЕЩ',
  'САНУЗЕЛ',
  'ПЕРИМЕТР',
  'ПЛОЩ_СТЕН',
  'ПЛОЩ_ПОЛА',
] as const;

export const OUTLAY_PROPS = [
  'С_СМЕТ',
  'СКИДКА_ОБЩАЯ',
  'К_СМЕТ',
  'СУММ_СМЕТ',
  'СУММ_ПЛОЩ',
  'СУММ_ПЕРИМ',
  'К_ФОТ_МАСТЕР',
  'К_ФОТ_НП_МАСТЕР',
  'К_ФОТ_ВАХТА',
  'К_ФОТ_НП_ВАХТА',
  'М_СМП',
] as const;

export const DEAL_PROPS = ['УСЛОВИЯ_РАБОТЫ', 'ДИЗАЙН_ПРОЕКТ'] as const;

export const COL_PROP_TRANSLATION_MAP = {
  E: 'H',
  F: 'K',
  G: 'L',
  H: 'M',
  I: 'N',
  J: 'O',
  K: 'P',
  L: 'Q',
  M: 'R',
  N: 'S',
  O: 'T',
  II: 'NN',
  JJ: 'OO',
  HH: 'MM',
} as const;

export const COL_PROP_MAP = {
  H: 'basePrice',
  I: 'masterBasePrice',
  J: 'shiftBasePrice',
  K: 'quantity',
  L: 'coefficient',
  M: 'discount',
  N: 'price',
  O: 'sum',
  P: 'masterPrice',
  Q: 'masterWage',
  R: 'shiftPrice',
  S: 'shiftWage',
  T: 'technologyDisclaimer',
  // HACK: w/o discount
  // these are "virtual" columns for the purpose of precisely calculating price/sum without discount
  NN: 'fullPrice',   // same as 'price' but without discount
  OO: 'fullSum',     // same as 'sum' but based on 'fullPrice' instead of 'price'
  MM: 'discountSum', // diff between discounted 'sum' and 'fullSum' w/o discount
} as const;

export const WORK_PROPS = [
  'quantity',
  'coefficient',
  'discount',
  'fullPrice',   // HACK: w/o discount
  'price',
  'fullSum',     // HACK: w/o discount
  'sum',
  'discountSum', // HACK: w/o discount
  'masterPrice',
  'masterWage',
  'shiftPrice',
  'shiftWage',
  'bonus',
  'technologyDisclaimer',
] as const;

export const ROUND_PROPS = [
  'quantity',
  'fullPrice',   // HACK: w/o discount
  'price',
  'fullSum',     // HACK: w/o discount
  'sum',
  'discountSum', // HACK: w/o discount
  'masterPrice',
  'shiftPrice',
  'masterWage',
  'shiftWage',
] as const;
