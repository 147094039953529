import {
  DEAL_EDIT_OUTLAY,
  OUTLAY_CHANGE_ACT_NUMBER,
  OUTLAY_CUSTOM_POSITION_CHANGE_NAME,
  OUTLAY_EDIT_SELECTED_OUTLAY,
  OUTLAY_EDIT_SUB_ACT,
  OUTLAY_POSITION_CHANGE_ACT_NUMBER,
  OUTLAY_POSITION_CHANGE_PRICE_MANUAL,
  OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL,
  OUTLAY_POSITION_TURN,
  OUTLAY_ROOM_CHANGE_BATHROOM_STATUS,
  OUTLAY_ROOM_CHANGE_HEIGHT,
  OUTLAY_ROOM_CHANGE_LENGTH,
  OUTLAY_ROOM_CHANGE_OPENINGS,
  OUTLAY_ROOM_CHANGE_PARAMS,
  OUTLAY_ROOM_CHANGE_WIDTH,
  OUTLAY_ADD_PROMOTION,
  OUTLAY_REMOVE_PROMOTION,
  OUTLAY_EDIT_PROMOTION,
  OUTLAY_ROOM_CHANGE_NAME,
  OUTLAY_ROOM_CHANGE_TYPE,
  OUTLAY_POSITION_CHANGE_ACT_QUANTITY,
} from 'store/constants';

export const reduceThunk = ({ type, payload = {}, doNotUpdate }) => {
  if (doNotUpdate) return null;
  const calc = window.calculator;
  if (!calc) return null;

  switch (type) {
    case OUTLAY_POSITION_TURN:
      return () => {
        calc.updatePosValue(payload.roomPositionId, payload.roomId, 'isTurnedOff', payload.value);
      };
    case OUTLAY_CUSTOM_POSITION_CHANGE_NAME:
      return () => {
        calc.updatePosValue(payload.positionId, payload.roomId, 'customName', payload.name);
      };
    case OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL:
      return () => {
        calc.updatePosValue(payload.roomPositionId, payload.roomId, 'quantity', payload.quantity);
      };
    case OUTLAY_POSITION_CHANGE_ACT_NUMBER:
      return () => {
        calc.updatePosValue(
          payload.roomPositionId || payload.roomComplexPositionId || payload.roomTemplateId,
          payload.roomId,
          'actNumber',
          payload.actNumber
        );
      };
    case OUTLAY_POSITION_CHANGE_PRICE_MANUAL:
      return () => {
        calc.updatePosValue(payload.roomPositionId, payload.roomId, 'price', payload.price);
      };
    case OUTLAY_POSITION_CHANGE_ACT_QUANTITY:
      return () => {
        calc.updatePosValue(payload.roomPositionId, payload.roomId, 'actQuantities', payload.actQuantities);
      };
    case OUTLAY_ROOM_CHANGE_NAME:
      return () => {
        const name = payload.name || null;
        calc.updateRoomValue(payload.roomId, 'name', name);
        if (name !== null) calc.updateRoomValue(payload.roomId, 'roomType', null);
      };
    case OUTLAY_ROOM_CHANGE_TYPE:
      return () => {
        const roomType = payload.roomType || null;
        calc.updateRoomValue(payload.roomId, 'roomType', roomType);
        if (roomType === null) calc.updateRoomValue(payload.roomId, 'roomTypeId', null);
        else calc.updateRoomValue(payload.roomId, 'name', null);
      };
    case OUTLAY_ROOM_CHANGE_WIDTH:
      return () => {
        calc.updateRoomValue(payload.roomId, 'width', payload.data);
      };
    case OUTLAY_ROOM_CHANGE_PARAMS:
      return () => {
        const { data, roomId } = payload;
        
        for (const [key, value] of Object.entries(data)) {
          calc.updateRoomValue(roomId, key, value);
        }
      };
    case OUTLAY_ROOM_CHANGE_BATHROOM_STATUS:
      return () => {
        calc.updateRoomValue(payload.roomId, 'isBathroom', payload.value);
      };
    case OUTLAY_ROOM_CHANGE_HEIGHT:
      return () => {
        calc.updateRoomValue(payload.roomId, 'height', payload.data);
      };
    case OUTLAY_ROOM_CHANGE_OPENINGS:
      return () => {
        calc.updateRoomValue(payload.roomId, 'openings', payload.data);
      };
    case OUTLAY_ROOM_CHANGE_LENGTH:
      return () => {
        calc.updateRoomValue(payload.roomId, 'length', payload.data);
      };
    case OUTLAY_ADD_PROMOTION:
      return () => {
        calc.addPromotion(payload.promotion);
      };
    case OUTLAY_REMOVE_PROMOTION:
      return () => {
        calc.removePromotion(payload.promotionId);
      };
    case OUTLAY_EDIT_PROMOTION:
      return () => {
        calc.updatePromotionValue(payload.promotionId, payload.data);
      };
    case DEAL_EDIT_OUTLAY:
    case OUTLAY_EDIT_SELECTED_OUTLAY: {
      return () => {
        for (const [key, value] of Object.entries(payload.data)) {
          calc.updateOutlayValue(key, value);
        }
      };
    }
    case OUTLAY_CHANGE_ACT_NUMBER:
      return () => {
        calc.updateActNumber(payload.actNumber);
      };
    case OUTLAY_EDIT_SUB_ACT:
      return () => {
        calc.updateSubAct(payload.actNumber, payload.data);
      };
    default:
      return null;
  }
};
