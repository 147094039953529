import { CalcOutlay, CalcAct, getSaver, getUpdater } from 'logic';
import { perf } from 'shared/utils';
import { dispatch, store } from 'store';

import { editOutlayFromDeal } from 'store/Deal/actions';
import { recalculateOutlay } from 'store/Outlay/actions';

import { getIdbData, getStateData } from './getData';
import { triggerSet } from './triggerSet';

const calcListener = (e) => {
  const result = e.target.result;
  dispatch(recalculateOutlay(result));
  dispatch(editOutlayFromDeal(result.id, {
    sum: result.sum,
    dealId: result.dealId,
    discount: result.discount,
    discountValue: result.discountValue,
    isDiscountValid: result.isDiscountValid,
  }, result.type, true));
};

const calculate = (stateData, idbData, actNumber) => {
  const timer = perf('calculator');

  const isAct = stateData[1].type === 'act';
  const Calc = isAct ? CalcAct : CalcOutlay;
  window.calculator = new Calc(...stateData, ...idbData, isAct ? actNumber : undefined);
  window.calculator.addEventListener('complete', calcListener);
  window.calculator.calculateAll();

  timer();
};

export const effectHandler = async () => {
  const state = store.getState();
  const lastAction = state.lastAction;

  const stateData = getStateData(state);
  const idbData = await getIdbData();

  const dataExists = !!(stateData && idbData);
  const calcExists = !!window.calculator;
  const isActionable = triggerSet.has(lastAction.type);
  const isCalculable = dataExists && (!calcExists || isActionable);

  if (isCalculable) calculate(
    stateData,
    idbData,
    state.outlay.actNumber,
  );

  getSaver().addAction(lastAction);
  getUpdater().addAction(lastAction);
};
