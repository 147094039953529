import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, GoogleOutlined } from '@ant-design/icons';

import { request, isNonEmpty } from 'shared/utils';

const paramsToObject = (params: URLSearchParams) => {
  const result: { [key: string]: string } = {};
  for (const [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
};

const useGoogleAuth = () => {
  const [isOk, setIsOk] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [params] = useSearchParams();

  useEffect(() => {
    const { error, code, state } = paramsToObject(params);
    if (!error && !code && !state) return;
    setIsLoading(false);
  }, [params]);

  useEffect(() => {
    if (isLoading) return;

    const { error, code, state } = paramsToObject(params);
    if (isNonEmpty(error) || !isNonEmpty(code) || !isNonEmpty(state)) {
      setIsOk(false);
      setData(error || 'Непредвиденная ошибка');
      return;
    }

    request
      .get('/backend/google', { error, code, state }, { baseUrl: __APP_BACKEND__ })
      .then((result: any) => {
        setData(result?.message || result?.data || result);
        setIsLoading(false);
      })
      .catch((result) => {
        setData(result?.message || result?.data || result);
        setIsLoading(false);
        setIsOk(false);
      });
  }, [isLoading]);

  return { isOk, isLoading, data };
};

const LoadingIcon = () => <GoogleOutlined spin style={{ color: '#1890ff', fontSize: '24px' }} />;
const OkIcon = () => <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '24px' }} />;
const ErrorIcon = () => <ExclamationCircleOutlined style={{ color: '#f5222d', fontSize: '24px' }} />;

export const GoogleAuth = () => {
  let { isOk, isLoading, data } = useGoogleAuth();

  return (
    <Card
      hoverable
      size='default'
      style={{ width: 'calc(100% - 100px)', marginTop: 'calc(50vh - 100px)' }}
    >
      <Card.Meta
        avatar={isLoading ? <LoadingIcon /> : isOk ? <OkIcon /> : <ErrorIcon />}
        title='Авторизация Google API'
        description={data || '...'}
      />
    </Card>
  );
};
