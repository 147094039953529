import { GENDER_MAP } from './const';
import { DecRule, DecRuleSet, GramCaseIdx, GramGender, GramGenderIdx, Name, NameTuple } from './types';

// class DeclensionsError extends Error {
//   constructor(name: string) {
//     super(`Could not decline: ${JSON.stringify(name)}`);
//     this.name = 'DeclensionError';
//   }
// }

export const fitNameToTuple = (name: Name): NameTuple => {
  if (Array.isArray(name)) return name;
  return [name.lastName, name.firstName, name.middleName, name.gender];
};

const FEMININE_RGX = /на|кызы|кузу$/;
const MASCULINE_RGX = /ич|оглы|оглу$/;
export const getGender = (middleName?: string): GramGender => {
  if (!middleName) return 'neuter';
  if (FEMININE_RGX.test(middleName)) return 'feminine';
  if (MASCULINE_RGX.test(middleName)) return 'masculine';
  return 'neuter';
};

const NAME_SEPARATOR_RGX = /-| |[А-ЯЁа-яё]+/g;
export const splitName = (name: string): string[] => name.match(NAME_SEPARATOR_RGX) || [];

const findLocalRule = (ruleSet: DecRule[], name: string, genderIdx: GramCaseIdx) => {
  const aptName = name.toLowerCase();
  for (const rule of ruleSet) {
    if (rule.genderIdx !== GENDER_MAP.neuter && rule.genderIdx !== genderIdx) continue;
    if (rule.tests.every((test) => !aptName.endsWith(test))) continue;
    return rule;
  }
  return null;
};

export const findGlobalRule = (ruleSet: DecRuleSet, name: string, genderIdx: GramGenderIdx) => {
  if (!ruleSet.irregular) return findLocalRule(ruleSet.regular, name, genderIdx);
  return findLocalRule(ruleSet.irregular, name, genderIdx) || findLocalRule(ruleSet.regular, name, genderIdx);
};

const PLACES_SUFFIX_RGX = /-{1,3}|[А-ЯЁа-яё]{1,5}/g;
export const getLengthAndSuffix = (mod: string): [number, string] => {
  if (!mod.startsWith('-')) return [0, mod];
  const [places, suffix] = mod.match(PLACES_SUFFIX_RGX) || [];
  return [places?.length || 0, suffix];
};

export const applyRule = (
  ruleSet: DecRuleSet,
  name: string,
  caseIdx: GramCaseIdx,
  genderIdx: GramGenderIdx,
) => {
  const rule = findGlobalRule(ruleSet, name, genderIdx);
  if (!rule) return name;
  // if (!rule) throw new DeclensionsError(namePart);

  if (!rule.mods || !rule.mods[caseIdx]) return name;

  const mod = rule.mods[caseIdx]!;
  const [length, suffix] = getLengthAndSuffix(mod);
  return `${name.slice(0, length ? -length : undefined)}${suffix}`;
};
