const REGULAR_PROPS = { color: '#1890ff', text: 'Рабочая' };
const MASTER_PROPS = { color: '#52c41a', text: 'Сводный' };
const FINAL_PROPS = { color: '#2f54eb', text: 'Общая' };
const EXTRA_PROPS = { color: '#13c2c2', text: 'Дополнительная' };

/**
 * Derives Ant Design `Ribbon` props (hex `color` and `text` content) based on outlay parameters
 * @arg {Object} data Outlay object
 * @arg {'outlay'|'act'} [data.type='outlay'] Outlay type
 * @arg {boolean} [data.isFinal=false] Final outlay status
 * @arg {boolean} [data.isExtra=false] Extra outlay status
 * @returns {{ color: string, text: string }} `Ribbon` props
 */

const deriveOutlayRibbon = (data = {}) => {
  if (!data) return REGULAR_PROPS;
  const { type = 'outlay', isFinal = false, isExtra = false } = data;

  if (type === 'act') return MASTER_PROPS;
  if (isFinal) return FINAL_PROPS;
  if (isExtra) return EXTRA_PROPS;
  return REGULAR_PROPS;
};

export default deriveOutlayRibbon;
