import { Big } from 'shared/big';
import { isParsable } from 'shared/utils';

interface TernarizeOptions extends Partial<Omit<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>> {
  suffix?: string,
  min?: number,
  max?: number,
  locale?: string,
}

export const ternarize = (
  number: string | number | Big = 0,
  options: TernarizeOptions = {},
) => {
  const { min = 0, max = 0, suffix = '₽', locale = 'ru', ...restOptions } = options;

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
    ...restOptions,
  });

  const aptNumber = isParsable(number) ? new Big(number).toScale(max) : 0;
  return `${formatter.format(aptNumber as any)} ${suffix ?? ''}`.trim();
};
