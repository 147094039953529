import { combineReducers } from 'redux';

import { dealReducer } from './Deal/reducers';
import { outlayReducer } from './Outlay/reducers';
import { mainReducer } from './mainReducer';
import { workFilterReducer } from './WorkFilter/reducers';
import { sessionReducer } from './Session/reducers';

const lastAction = (_state = null, action) => action;

export default combineReducers({
  app: mainReducer,
  deal: dealReducer,
  outlay: outlayReducer,
  workFilter: workFilterReducer,
  session: sessionReducer,
  lastAction,
});
