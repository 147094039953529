import { COL_PROP_TRANSLATION_MAP, WORK_PROPS } from ".";

const WORK_COL_PROP_RGX = /[A-Z](?=[0-9]{1,3})/g;
const DISCOUNT_CELL_RGX = /\$?M[0-9]{1,3}/g;
const FINAL_PRICE_COL_RGX = /N(?=[0-9]{1,3})/g;
const UNDISCOUNTABLE_WORK_TYPES = ['Натяжной потолок', 'Спецмонтаж', 'Клининг'];
const HEIGHT_COEF_APPLICABLE_MAT_TYPES = ['стены', 'потолки'];

const colPropReplacer = (colProp) => COL_PROP_TRANSLATION_MAP[colProp];

const UNDISCOUNTABLE_POSITION = {
  id: 0,
  excelRow: 0,
  coefficientFormula: '1',
  discountFormula: '1',
  fullPriceFormula: '=H0',
  priceFormula: '=H0',
  fullSumFormula: '=K0*NN0',
  // sumFormula: '=K0*NN0',
  sumFormula: '=K0*N0',
  discountSumFormula: '0',
  masterPriceFormula: '=H0*К_ФОТ_НП_МАСТЕР',
  masterWageFormula: '=K0*P0',
  shiftPriceFormula: '=H0*К_ФОТ_НП_ВАХТА',
  shiftWageFormula: '=K0*R0',
};

const HEIGHT_COEF_POSITION = {
  id: 0,
  excelRow: 0,
  coefficientFormula: '=IF(ВЫСОТА>3.5,1.25,IF(ВЫСОТА>3,1.15,1))',
  discountFormula: '=(100-С_СМЕТ)/100',
  fullPriceFormula: '=H0*L0*К_СМЕТ*К_ПОМЕЩ',
  priceFormula: '=H0*L0*К_СМЕТ*К_ПОМЕЩ*M0',
  fullSumFormula: '=K0*NN0',
  // sumFormula: '=K0*NN0*M0',
  sumFormula: '=K0*N0',
  discountSumFormula: '=OO0-O0',
  masterPriceFormula: '=IF(К_СМЕТ>=1.3,1.2,IF(К_СМЕТ>=1.1,1.1,1))*К_ПОМЕЩ*IF(ВЫСОТА>3.5,1.1,IF(ВЫСОТА>3,1.05,1))*H0*К_ФОТ_МАСТЕР',
  masterWageFormula: '=K0*P0',
  shiftPriceFormula: '=IF(К_СМЕТ>=1.3,1.2,IF(К_СМЕТ>=1.1,1.1,1))*К_ПОМЕЩ*IF(ВЫСОТА>3.5,1.1,IF(ВЫСОТА>3,1.05,1))*H0*К_ФОТ_ВАХТА',
  shiftWageFormula: '=K0*R0',
};

const REGULAR_POSITION = {
  id: 0,
  excelRow: 0,
  coefficientFormula: '1',
  discountFormula: '=(100-С_СМЕТ)/100',
  fullPriceFormula: '=H0*L0*К_СМЕТ*К_ПОМЕЩ',
  priceFormula: '=H0*L0*К_СМЕТ*К_ПОМЕЩ*M0',
  fullSumFormula: '=K0*NN0',
  // sumFormula: '=K0*NN0*M0',
  sumFormula: '=K0*N0',
  discountSumFormula: '=OO0-O0',
  masterPriceFormula: '=IF(К_СМЕТ>=1.3,1.2,IF(К_СМЕТ>=1.1,1.1,1))*К_ПОМЕЩ*H0*К_ФОТ_МАСТЕР',
  masterWageFormula: '=K0*P0',
  shiftPriceFormula: '=IF(К_СМЕТ>=1.3,1.2,IF(К_СМЕТ>=1.1,1.1,1))*К_ПОМЕЩ*H0*К_ФОТ_ВАХТА',
  shiftWageFormula: '=K0*R0',
};

export const augmentWork = (work, room, workTypes, matTypes, hasRid = false) => {
  work.room = room;
  work.isSurrogate = false;

  if (work.custom) {
    const workType = (work.customWorkTypeId && workTypes.find(({ id }) => id === work.customWorkTypeId)) || null;
    // work.hasDiscount = !!(workType && !UNDISCOUNTABLE_WORK_TYPES.includes(workType.name));
    work.hasDiscount = !workType || !UNDISCOUNTABLE_WORK_TYPES.includes(workType.name);
  
    const matType = (work.customMaterialTypeId && matTypes.find(({ id }) => id === work.customMaterialTypeId)) || null;
    const isHeightCoefApplicable = !!(work.hasDiscount && matType && HEIGHT_COEF_APPLICABLE_MAT_TYPES.includes(matType.name));
  
    work.position = work.hasDiscount ? isHeightCoefApplicable ? HEIGHT_COEF_POSITION : REGULAR_POSITION : UNDISCOUNTABLE_POSITION;
    
    return;
  }

  work.hasDiscount = work.position.discountFormula !== '1';
  if (work.position.isModified) return;
  work.position.isModified = true;

  // HACK: translate old version formulae
  if (!hasRid) {
    for (const prop of WORK_PROPS) {
      const fullProp = `${prop}Formula`;
      if (!work.position[fullProp]) continue;
      work.position[fullProp] = work.position[fullProp].replace(WORK_COL_PROP_RGX, colPropReplacer);
    }
  }

  // HACK: w/o discount
  work.position.fullPriceFormula = work.position.priceFormula.replace(DISCOUNT_CELL_RGX, 1);
  work.position.fullSumFormula = work.position.sumFormula.replace(FINAL_PRICE_COL_RGX, 'NN');
  work.position.discountSumFormula = `OO${work.position.excelRow}-O${work.position.excelRow}`;
};
