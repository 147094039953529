import { ExperimentFilled } from '@ant-design/icons';

import { nomenclate, localize, ternarize } from 'shared/format';
import { InfoTag } from 'components';

const percent = (value) => ternarize(value, { min: 2, max: 2, suffix: '%' });
const abs = (value) => ternarize(value, { min: 2, max: 2 });

const infoCardLayout = [
  {
    key: 'user',
    label: 'Автор',
    transform: (value) => nomenclate(value),
  },
  {
    key: 'createdAt',
    label: 'Дата создания',
    transform: (value) => localize(value, 'date'),
  },
  {
    key: 'updatedAt',
    label: 'Дата изменения',
    transform: (value) => localize(value, 'date'),
  },
  {
    key: 'brand',
    label: 'Бренд',
  },
  {
    key: 'org',
    label: 'Юр. лицо',
  },
  {
    key: 'maxDiscount',
    label: 'Макс. скидка',
    transform: (_, record) => record?.type !== 'act'
      ? `${percent(record?.maxDiscount)} (${abs(record?.maxDiscountValue)})`
      : `${abs(record?.maxDiscountValue)} (${percent(record?.maxDiscount)})`,
  },
  {
    key: 'discount',
    label: 'Скидка',
    transform: (_, record) => record?.type !== 'act'
      ? `${percent(record?.discount)} (${abs(record?.discountValue)})`
      : `${abs(record?.discountValue)} (${percent(record?.discount)})`,
  },
  {
    key: 'remainingDiscount',
    label: 'Осталось',
    transform: (_, record) => record?.type !== 'act'
      ? `${percent(record?.remainingDiscount)} (${abs(record?.remainingDiscountValue)})`
      : `${abs(record?.remainingDiscountValue)} (${percent(record?.remainingDiscount)})`,
  },
  {
    key: 'technologyDisclaimer',
    label: 'Свойства',
    render: (value) =>
      value === true ? (
        <InfoTag color='volcano' icon={<ExperimentFilled />} style={{ width: 'min-content', height: '18px' }}>
          Отказ от технологий
        </InfoTag>
      ) : (
        '-'
      ),
  },
  {
    key: 'versionId',
    label: 'Версия',
    transform: (value) => '№' + value,
  },
  {
    key: 'parentOutlayId',
    label: 'Оригинал',
    transform: (value) => (value ? '№' + value : '-'),
  },
];

export default infoCardLayout;
