import { Button, Modal, notification } from 'antd';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { logger } from 'shared/utils';
import { clearIdb } from 'shared/idb';

interface PromptFooterProps {
  offlineReady: boolean,
  setOfflineReady: (arg: boolean) => void,
  setNeedRefresh: (arg: boolean) => void,
  updateServiceWorker: (arg: boolean) => void,
}

const notifyError = (error: Error) => {
  logger.error('sw', error);
  notification.error({
    duration: 6,
    message: 'Смета С: Ошибка при обновлении',
    description: 'Новая версия не была установленна, повторите позже',
  });
};

const PromptFooter = ({ offlineReady, setOfflineReady, setNeedRefresh, updateServiceWorker }: PromptFooterProps) => (
  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <Button onClick={() => { setOfflineReady(false); setNeedRefresh(false); }}>
      Закрыть
    </Button>
    {offlineReady || (
      <Button
        type='primary'
        onClick={async () => {
          try {
            await clearIdb();
            updateServiceWorker(true); 
          } catch (error: any) {
            notifyError(error);
          }
        }}
      >
        Обновить
      </Button>
    )}
  </div>
);

export const Prompt = () => {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(url) {
      logger.log('sw', `registered at ${url}`);
    },
    onRegisterError: notifyError,
  });

  return (
    <Modal
      centered
      destroyOnClose
      closable={false}
      open={offlineReady || needRefresh}
      title={`Смета С: ${needRefresh ? 'Новое обновление' : 'Приложение установлено'}`}
      footer={<PromptFooter
        offlineReady={offlineReady}
        setOfflineReady={setOfflineReady}
        setNeedRefresh={setNeedRefresh}
        updateServiceWorker={updateServiceWorker}
      />}
    >
      {offlineReady ? 'Приложение готово к работе оффлайн' : `Версия ${__APP_VERSION__} устарела. Для корректной работы требуется обновление`}
    </Modal>
  );
};
