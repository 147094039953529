import { deleteCache, getCache, setCache } from './cache';

type User = {
  id: number,
  id1c: string,
  firstName: string,
  middleName: string,
  surname: string,
  login: string,
  pseudonym: string,
  role: string,
  userTypeId: number,
  userType: { id: number, name: string },
  childUsers?: Omit<User, 'role' | 'login'>[],
};

const KEY = 'USER';

export const getCurUser = (): User | null => {
  const cached = getCache<User>(KEY);
  if (cached) return cached;

  const serialized = localStorage.getItem(KEY);
  const result = serialized && JSON.parse(serialized);
  setCache(KEY, result);
  return result;
};

export const setCurUser = (user: User) => {
  setCache(KEY, user);
  localStorage.setItem(KEY, JSON.stringify(user));
};

export const removeCurUser = () => {
  deleteCache(KEY);
  localStorage.removeItem(KEY);
};
