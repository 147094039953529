import { connect } from 'react-redux';

import deriveOutlayAndOrg from './deriveOutlayAndOrg';
import OutlayParamDrawer from './OutlayParamDrawer/OutlayParamDrawer';

import { editOutlayFromDeal } from 'store/Deal/actions';
import { editSelectedOutlay, editSubAct } from 'store/Outlay/actions';

const OutlayParamDrawerContainer = ({
  editOutlayFromDeal,
  editSelectedOutlay,
  editSubAct,
  selectedOutlay,
  selectedDeal,
  actNumber,
  isVisible,
  setIsVisible,
}) => (
  <OutlayParamDrawer
    data={{
      ...deriveOutlayAndOrg(selectedOutlay, selectedDeal),
      actNumber,
      setIsVisible: () => setIsVisible(!isVisible),
      editOutlay: (key, value) => {
        if (!key) return;
        editOutlayFromDeal(selectedOutlay?.id, { [key]: value }, selectedOutlay.type);
        editSelectedOutlay({ [key]: value });
      },
      editSubAct: (key, value, actNumber) =>
        editSubAct(selectedOutlay.id, actNumber, { [key]: value }),
    }}
    open={isVisible}
  />
);

const mapStateToProps = (state) => ({
  selectedDeal: state.deal.selectedDeal,
  selectedOutlay: state.outlay.selectedOutlay,
  actNumber: state.outlay.actNumber,
});

const mapDispatchToProps = {
  editOutlayFromDeal,
  editSelectedOutlay,
  editSubAct,
};

export const OutlayParams = connect(mapStateToProps, mapDispatchToProps)(OutlayParamDrawerContainer);
