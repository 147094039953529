import {
  APP_LOAD_FILE,
  OUTLAY_ADD_ROOM,
  OUTLAY_CHANGE_SELECTED,
  OUTLAY_CREATE_SUB_ACT,
  OUTLAY_DELETE_ROOM,
  OUTLAY_IDB_VERSION_CHANGE,
  OUTLAY_ROOM_ADD_POSITIONS,
  OUTLAY_ROOM_DELETE_POSITION,
  OUTLAY_CHANGE_ROOM_ORDER,
} from 'store/constants';

export const triggerSet = new Set([
  APP_LOAD_FILE,
  OUTLAY_CHANGE_ROOM_ORDER,
  OUTLAY_CHANGE_SELECTED,
  OUTLAY_ADD_ROOM,
  OUTLAY_DELETE_ROOM,
  OUTLAY_ROOM_ADD_POSITIONS,
  OUTLAY_ROOM_DELETE_POSITION,
  OUTLAY_CREATE_SUB_ACT,
  OUTLAY_IDB_VERSION_CHANGE,
]);
