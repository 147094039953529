import { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import styles from './InfoTag.module.scss';

const COLOR_MAP = {
  grey: '#f0f0f0',
  red: '#ff7875',
  volcano: '#ff9c6e',
  orange: '#ffc069',
  gold: '#ffd666',
  yellow: '#fff566',
  lime: '#d3f261',
  green: '#95de64',
  cyan: '#5cdbd3',
  blue: '#69c0ff',
  geekblue: '#85a5ff',
  purple: '#b37feb',
  magenta: '#ff85c0',
};

export interface InfoTagProps extends PropsWithChildren {
  color?: keyof typeof COLOR_MAP,
  icon?: ReactNode,
  style?: CSSProperties,
}

export const InfoTag: React.FC<InfoTagProps> = ({ color = 'grey', icon, style = {}, children }) => (
  <div className={styles.Container} style={{ ...style, backgroundColor: COLOR_MAP[color] }}>
    {typeof icon !== 'string' ? icon : (<span>{icon}</span>)}
    {typeof children !== 'string' ? children : (<span>{children}</span>)}
  </div>
);
