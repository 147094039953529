// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'antd/dist/antd.variable.css';
import 'moment/locale/ru';

import 'shared/polyfill';
import { dispatch, store } from 'store';
import { effectHandler } from 'logic';
import { getVersionIdb } from 'shared/idb';
import { idbVersionChange } from 'store/Outlay/actions';
import * as big from 'shared/big';

import './index.css';
import './fonts/IBMPlexSans.css';
import './fonts/THICCCBOI.css';

import App from './App';

window.ctx = { isMail: false } as any;
Object.assign(window, big);
store.subscribe(effectHandler);
getVersionIdb().addEventListener(
  'load',
  () => dispatch(idbVersionChange()),
);

createRoot(document.getElementById('root')!)
  // TODO: upgrade to antd v5
  // .render(<StrictMode><App /></StrictMode>);
  .render(<App />);
