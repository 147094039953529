if (!window.MediaQueryList.prototype.addEventListener) window.MediaQueryList.prototype.addEventListener = function addEventListener(
  type: 'change',
  listener: ((this: MediaQueryList, event: MediaQueryListEvent) => any) | { handleEvent: (object: Event) => void },
) {
  if (type !== 'change') return;
  this.addListener('handleEvent' in listener ? listener.handleEvent : listener);
};

if (!window.MediaQueryList.prototype.removeEventListener) window.MediaQueryList.prototype.removeEventListener = function removeEventListener(
  type: 'change',
  listener: ((this: MediaQueryList, event: MediaQueryListEvent) => any) | { handleEvent: (object: Event) => void },
) {
  if (type !== 'change') return;
  this.removeListener('handleEvent' in listener ? listener.handleEvent : listener);
};

export {};
