import {
  APP_LOAD_FILE,
  DEAL_ADD_NEW_OUTLAY,
  DEAL_DELETE_OUTLAY,
  DEAL_EDIT_DATA,
  DEAL_EDIT_OUTLAY,
  OUTLAY_ADD_ROOM,
  OUTLAY_CHANGE_POSITION_ORDER,
  OUTLAY_CHANGE_ROOM_ORDER,
  OUTLAY_CUSTOM_POSITION_CHANGE_NAME,
  OUTLAY_DELETE_ROOM,
  OUTLAY_POSITION_CHANGE_ACT_NUMBER,
  OUTLAY_POSITION_CHANGE_PRICE_MANUAL,
  OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL,
  OUTLAY_POSITION_CHANGE_ACT_QUANTITY,
  OUTLAY_POSITION_CHANGE_UNIT,
  OUTLAY_POSITION_TURN,
  OUTLAY_RECALCULATE_VALUES,
  OUTLAY_ROOM_ADD_POSITIONS,
  OUTLAY_ROOM_CHANGE_BATHROOM_STATUS,
  OUTLAY_ROOM_CHANGE_HEIGHT,
  OUTLAY_ROOM_CHANGE_LENGTH,
  OUTLAY_ROOM_CHANGE_NAME,
  OUTLAY_ROOM_CHANGE_OPENINGS,
  OUTLAY_ROOM_CHANGE_PARAMS,
  OUTLAY_ROOM_CHANGE_WIDTH,
  OUTLAY_ROOM_DELETE_POSITION,
  OUTLAY_EDIT_PROMOTION,
  OUTLAY_EDIT_SUB_ACT,
  OUTLAY_CREATE_SUB_ACT,
  OUTLAY_ADD_PROMOTION,
  OUTLAY_REMOVE_PROMOTION,
  DEAL_SET_CLIENT_INFO,
  OUTLAY_ROOM_CHANGE_TYPE,
  OUTLAY_ADD_USER_TEMPLATE,
  OUTLAY_DELETE_USER_TEMPLATE,
} from 'store/constants';
import { SESSION_ADD_EVENT, SESSION_ARCHIVE_EVENT } from 'store/Session/constants';
import { changeOutlayId } from 'store/Deal/actions';
import { request, socket } from 'shared/utils';
import { dispatch } from 'store';
import { deleteDoc, updateDoc } from 'store/Outlay/actions';

export const reduceThunk = (
  /** @type {{ type: string, payload: { [key: string]: any } }} */ { type, payload = {} },
) => {
  switch (type) {
    case DEAL_EDIT_DATA: {
      return async () => {
        const { id, client = {}, ...deal } = payload.data;

        await request.patch('/deals/' + id, { ...deal, ...client });
      };
    }
    case DEAL_ADD_NEW_OUTLAY: {
      if (payload.isMasterAct) return null;
      
      return async () => {
        const outlay = payload.data;

        const data = await request.post('/outlays', outlay);
        const newId = data.outlayId;
        const oldId = payload.data.id;
        dispatch(changeOutlayId(oldId, newId, data.outlay));
      };
    }
    case DEAL_DELETE_OUTLAY: {
      return async () => {
        const id = payload.outlayId;

        await request.delete('/outlays/' + id);
      };
    }
    case DEAL_EDIT_OUTLAY: {
      return async () => {
        const { outlayId: id, data: outlay } = payload;

        await request.patch('/outlays/' + id, outlay);
      };
    }
    case OUTLAY_DELETE_ROOM: {
      return async () => {
        const { roomId } = payload;

        await request.delete('/rooms/' + roomId);
      };
    }
    // case OUTLAY_ROOM_CLONE: {
    //   return async () => {
    //     const { roomId: id, uuid } = payload;

    //     await api.post('/rooms/copy', { id, uuid });

    //     // const data = await api.post('/rooms/copy', { id, uuid });
    //     // const { roomId, positions } = data;

    //     // calc?.updateRoomId(uuid, roomId);
    //     // dispatch(changeRoomId(uuid, roomId));

    //     // for (const { source, target } of positions) {
    //     //   calc?.updatePositionId(roomId, source.id, target.id);
    //     //   dispatch(changePositionId(roomId, source.id, target.id));
    //     // }
    //   };
    // }
    case OUTLAY_ADD_ROOM: {
      return async () => {
        await request.post('/rooms', payload.data);
        // const data = await api.post('/rooms', payload.data);

        // const newId = data.room.id;
        // const oldId = payload.data.id;
        // calc?.updateRoomId(oldId, newId);
        // dispatch(changeRoomId(oldId, newId));
      };
    }
    case OUTLAY_ROOM_CHANGE_NAME: {
      return async () => {
        const apiPayload = { name: payload.name || null };
        if (apiPayload.name !== null) apiPayload.roomTypeId = null;

        await request.patch('/rooms/' + payload.roomId, apiPayload);
      };
    }
    case OUTLAY_ROOM_CHANGE_TYPE: {
      return async () => {
        const apiPayload = { roomTypeId: payload.roomType?.id || null };
        if (apiPayload.roomTypeId !== null) apiPayload.name = null;

        await request.patch('/rooms/' + payload.roomId, apiPayload);
      };
    }
    case OUTLAY_ROOM_CHANGE_BATHROOM_STATUS: {
      return async () => {
        await request.patch('/rooms/' + payload.roomId, { isBathroom: payload.value });
      };
    }
    case OUTLAY_ROOM_CHANGE_WIDTH: {
      return async () => {
        await request.patch('/rooms/' + payload.roomId, { width: payload.data });
      };
    }
    case OUTLAY_ROOM_CHANGE_HEIGHT: {
      return async () => {
        await request.patch('/rooms/' + payload.roomId, { height: payload.data });
      };
    }
    case OUTLAY_ROOM_CHANGE_LENGTH: {
      return async () => {
        await request.patch('/rooms/' + payload.roomId, { length: payload.data });
      };
    }
    case OUTLAY_ROOM_CHANGE_OPENINGS: {
      return async () => {
        await request.patch('/rooms/' + payload.roomId, { openings: payload.data });
      };
    }
    case OUTLAY_ROOM_CHANGE_PARAMS: {
      return async () => {
        await request.patch('/rooms/' + payload.roomId, payload.data);
      };
    }
    case OUTLAY_ROOM_DELETE_POSITION: {
      return async () => {
        await request.delete(`/positions/${payload.id}`);
      };
    }
    case OUTLAY_CUSTOM_POSITION_CHANGE_NAME: {
      return async () => {
        await request.patch('/positions/' + payload.positionId, {
          params: { customName: payload.name },
        });
      };
    }
    case OUTLAY_POSITION_CHANGE_ACT_NUMBER: {
      return async () => {
        await request.patch('/positions/' + payload.roomPositionId, {
          params: { actNumber: payload.actNumber },
        });
      };
    }
    case OUTLAY_POSITION_CHANGE_UNIT: {
      return async () => {
        await request.patch('/positions/' + payload.positionId, {
          params: { customUnit: payload.unit },
        });
      };
    }
    case OUTLAY_POSITION_TURN: {
      return async () => {
        await request.patch('/positions/' + payload.roomPositionId, {
          params: { isTurnedOff: payload.value },
        });
      };
    }
    case OUTLAY_ROOM_ADD_POSITIONS: {
      return async () => {
        const { roomPositions, roomId } = payload;

        await request.post('/positions', {
          roomId: roomId,
          positions: roomPositions,
        });

        // const data = await api.post('/positions', {
        //   roomId: roomId,
        //   positions: roomPositions,
        //   type: 'simple',
        // });
        // const { roomPositionMap } = data;

        // for (const [oldId, newId] of roomPositionMap) {
        //   calc?.updatePositionId(roomId, oldId, newId);
        //   dispatch(changePositionId(roomId, oldId, newId));
        // }
      };
    }
    case OUTLAY_ADD_PROMOTION: {
      return async () => {
        await request.post('/promotions', payload);
        // const data = await api.post('/promotions', payload);
        // const { outlayPromotion } = data;

        // const oldId = payload.promotion.id;
        // const newId = outlayPromotion.id;
        // calc?.updatePromotionId(oldId, newId);
        // dispatch(changePromotionId(oldId, newId));
      };
    }
    case OUTLAY_REMOVE_PROMOTION: {
      return async () => {
        await request.delete('/promotions/' + payload.promotionId);
      };
    }
    case OUTLAY_EDIT_PROMOTION: {
      return async () => {
        const { promotionId, data } = payload;

        await request.patch('/promotions/' + promotionId, data);
      };
    }
    case OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL: {
      return async () => {
        await request.patch('/positions/' + payload.roomPositionId, {
          params: { quantityManual: payload.quantity },
        });
      };
    }
    case OUTLAY_POSITION_CHANGE_PRICE_MANUAL: {
      return async () => {
        await request.patch('/positions/' + payload.roomPositionId, {
          params: { priceManual: payload.price },
        });
      };
    }
    case OUTLAY_POSITION_CHANGE_ACT_QUANTITY: {
      return async () => {
        await request.patch('/positions/' + payload.roomPositionId, {
          params: { actQuantities: payload.actQuantities },
        });
      };
    }
    case OUTLAY_CHANGE_ROOM_ORDER: {
      return async () => {
        const { roomId, sourceIndex, destinationIndex } = payload;

        await request.post('/rooms/reorder', { roomId, sourceIndex, destinationIndex });
      };
    }
    case OUTLAY_CHANGE_POSITION_ORDER: {
      return async () => {
        const { roomId, sourceIndex, destinationIndex } = payload;

        await request.post('/positions/reorder', { roomId, sourceIndex, destinationIndex });
      };
    }
    case OUTLAY_RECALCULATE_VALUES: {
      return () => {
        // await request.patch('/outlays/' + payload.id, payload);

        const url = `/outlays/${payload.id}`;
        const subActUrl = `${url}/subAct`;

        const rooms = payload.rooms ?? [];
        payload.rooms = undefined;
        const promises = rooms.map((room) => request.patch(`/rooms/${room.id}`, room));

        const subActs = payload.subActs ?? [];
        payload.subActs = undefined;
        promises.push(...subActs.map((act) => request.patch(subActUrl, act)));

        promises.push(request.patch(url, payload));

        return Promise.all(promises);
      };
    }
    case APP_LOAD_FILE: {
      return async () => {
        const { deal, outlay, shouldOverwrite } = payload;

        const data = await request.post('/outlays/saveLoaded', { deal, outlay, shouldOverwrite });
        // const { outlayId, outlayNumber, rooms, roomPositions, subActs = [] } = data;
        // const { outlayId } = data;

        // dispatch(changeSelectedOutlayId(outlayId, outlayNumber));

        // for (const [oldId, newId] of rooms) {
        //   calc?.updateRoomId(oldId, newId);
        //   dispatch(changeRoomId(oldId, newId));
        // }

        // for (const [roomId, oldId, newId] of roomPositions) {
        //   calc?.updatePositionId(roomId, oldId, newId);
        //   dispatch(changePositionId(roomId, oldId, newId));
        // }

        // for (const [actNumber, newId] of subActs.entries()) {
        //   calc?.updateSubActId(actNumber, newId);
        //   dispatch(changeSubActId(outlayId, actNumber, newId));
        // }

        await window.router.navigate(`/deals/${deal.id}/outlay/${data.outlayId}`, { replace: true });
        window.location.reload();
      };
    }
    case OUTLAY_EDIT_SUB_ACT: {
      return async () => {
        await request.patch(`/outlays/${payload.outlayId}/subAct`, {
          ...payload.data,
          actNumber: payload.actNumber,
        });
      };
    }
    case OUTLAY_CREATE_SUB_ACT: {
      return async () => {
        const { subAct } = payload;

        await request.post(`/outlays/${subAct.outlayId}/subAct`, subAct);

        // const data = await api.post(`/outlays/${subAct.outlayId}/subAct`, subAct);
        // const {
        //   subAct: { actNumber, id },
        // } = data;

        // calc?.updateSubActId(actNumber, id);
        // dispatch(changeSubActId(actNumber, id));
      };
    }
    case DEAL_SET_CLIENT_INFO: {
      return async () => {
        const { dealId, data } = payload;
        if (!dealId) return;

        await request.patch('/sense/' + dealId, data || {});
      };
    }
    case OUTLAY_ADD_USER_TEMPLATE: {
      return async () => {
        const { userTemplate } = payload;

        await request.post('/templates', userTemplate);
      };
    }
    case OUTLAY_DELETE_USER_TEMPLATE: {
      return async () => {
        const { userTemplateId } = payload;

        await request.delete(`/templates/${userTemplateId}`);
      };
    }
    case SESSION_ADD_EVENT: {
      return () => {
        const event = payload.event;

        if (event.type === 'docUrl') return dispatch(updateDoc({
          id: event.docId,
          fileUrl: event.fileUrl,
          signUrl: event.signUrl,
        }));

        if (event.type !== 'doc') return;

        if (!event.docType.includes('ContCancelDoc'))
            return dispatch(updateDoc(event));

        if (event.signStatus === 'signed')
          return dispatch(deleteDoc(event.parameters.docId));
      };
    };
    case SESSION_ARCHIVE_EVENT: {
      return () => {
        const { eventId } = payload;

        socket.emit('event', { id: eventId, isArchived: true });
      };
    }
    default:
      return null;
  }
};
