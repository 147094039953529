import { saveAs } from 'file-saver';

import { encryptEstFile } from './est';
import { store } from 'store';

const TIME_RGX = /:,_/g;

const EXTENSION = '.est';
const getFileName = (id1c: number, id: number, type: 'outlay' | 'act') => {
  const name = type === 'act' ? 'АКТ' : 'СМЕТА';
  const cur = new Date();
  const date = cur.toLocaleDateString('ru');
  const time = cur.toLocaleTimeString('ru').replace(TIME_RGX, '.');
  return `ИД${id1c}_${name}${id}_${date}_${time}${EXTENSION}`;
};

const getData = (state: { [key: string]: any } = {}) => ({
  deal: state.deal?.selectedDeal ?? {},
  outlay: state.outlay?.selectedOutlay ?? {},
});

export const saveToFile = () => {
  const { deal, outlay } = getData(store.getState());

  const fileName = getFileName(deal.id1c, outlay.id, outlay.type);
  const cipher = encryptEstFile(JSON.stringify({ deal, outlay }));
  const file = new File([cipher], fileName, { type: 'text/plain' });

  saveAs(file);
};
