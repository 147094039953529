/* MAIN */
export const APP_LOAD_FILE = 'APP_LOAD_FILE';
export const HEADER_CHANGE_APP_STATUS = 'HEADER_CHANGE_APP_STATUS';

/* DEAL */
export const DEAL_FILL_LIST = 'DEAL_FILL_LIST';
export const DEAL_EDIT_DATA = 'DEAL_EDIT_DATA';
export const DEAL_CHANGE_SELECTED = 'DEAL_CHANGE_SELECTED';
export const DEAL_ADD_NEW_OUTLAY = 'DEAL_ADD_NEW_OUTLAY';
export const DEAL_COPY_OUTLAY = 'DEAL_COPY_OUTLAY';
export const DEAL_DELETE_OUTLAY = 'DEAL_DELETE_OUTLAY';
export const DEAL_EDIT_OUTLAY = 'DEAL_EDIT_OUTLAY';
export const DEAL_OUTLAY_CHANGE_ID = 'OUTLAY_CHANGE_ID';
export const DEAL_SET_CLIENT_INFO = 'DEAL_SET_CLIENT_INFO';

/* OUTLAY */
export const OUTLAY_CHANGE_SELECTED = 'OUTLAY_CHANGE_SELECTED';
export const OUTLAY_ADD_ROOM = 'OUTLAY_ADD_ROOM';
export const OUTLAY_DELETE_ROOM = 'OUTLAY_DELETE_ROOM';
export const OUTLAY_ROOM_ADD_POSITIONS = 'OUTLAY_ROOM_ADD_POSITIONS';
export const OUTLAY_ADD_PROMOTION = 'OUTLAY_ADD_PROMOTION';
export const OUTLAY_REMOVE_PROMOTION = 'OUTLAY_REMOVE_PROMOTION';
export const OUTLAY_EDIT_PROMOTION = 'OUTLAY_EDIT_PROMOTION';
export const OUTLAY_ROOM_DELETE_POSITION = 'OUTLAY_ROOM_DELETE_POSITION';
export const OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL = 'OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL';
export const OUTLAY_POSITION_CHANGE_PRICE_MANUAL = 'OUTLAY_POSITION_CHANGE_PRICE_MANUAL';
export const OUTLAY_CUSTOM_POSITION_CHANGE_NAME = 'OUTLAY_CUSTOM_POSITION_CHANGE_NAME';
export const OUTLAY_POSITION_CHANGE_ACT_NUMBER = 'OUTLAY_POSITION_CHANGE_ACT_NUMBER';
export const OUTLAY_POSITION_TURN = 'OUTLAY_POSITION_TURN';
export const OUTLAY_POSITION_CHANGE_UNIT = 'OUTLAY_POSITION_CHANGE_UNIT';
export const OUTLAY_POSITION_CHANGE_ACT_QUANTITY = 'OUTLAY_POSITION_CHANGE_ACT_QUANTITY';
export const OUTLAY_ROOM_CHANGE_NAME = 'OUTLAY_ROOM_CHANGE_NAME';
export const OUTLAY_ROOM_CHANGE_TYPE = 'OUTLAY_ROOM_CHANGE_TYPE';
export const OUTLAY_ROOM_CHANGE_WIDTH = 'OUTLAY_ROOM_CHANGE_WIDTH';
export const OUTLAY_ROOM_CHANGE_HEIGHT = 'OUTLAY_ROOM_CHANGE_HEIGHT';
export const OUTLAY_ROOM_CHANGE_OPENINGS = 'OUTLAY_ROOM_CHANGE_OPENINGS';
export const OUTLAY_ROOM_CHANGE_LENGTH = 'OUTLAY_ROOM_CHANGE_LENGTH';
export const OUTLAY_RECALCULATE_VALUES = 'OUTLAY_RECALCULATE_VALUES';
export const OUTLAY_ROOM_CHANGE_BATHROOM_STATUS = 'OUTLAY_ROOM_CHANGE_BATHROOM_STATUS';
export const OUTLAY_EDIT_SELECTED_OUTLAY = 'OUTLAY_EDIT_SELECTED_OUTLAY';
export const OUTLAY_CHANGE_ROOM_ORDER = 'OUTLAY_CHANGE_ROOM_ORDER';
export const OUTLAY_CHANGE_POSITION_ORDER = 'OUTLAY_CHANGE_POSITION_ORDER';
export const OUTLAY_ROOM_CHANGE_PARAMS = 'OUTLAY_ROOM_CHANGE_PARAMS';
export const OUTLAY_SET_PARENT_OUTLAY = 'OUTLAY_SET_PARENT_OUTLAY';
export const OUTLAY_IDB_VERSION_CHANGE = 'OUTLAY_IDB_VERSION_CHANGE';

/* SUB_ACT */
export const OUTLAY_CHANGE_ACT_NUMBER = 'OUTLAY_CHANGE_ACT_NUMBER';
export const OUTLAY_EDIT_SUB_ACT = 'OUTLAY_EDIT_SUB_ACT';
export const OUTLAY_CREATE_SUB_ACT = 'OUTLAY_CREATE_SUB_ACT';

/* USER_TEMPLATE */
export const OUTLAY_SET_USER_TEMPLATES = 'OUTLAY_SET_USER_TEMPLATES';
export const OUTLAY_ADD_USER_TEMPLATE = 'OUTLAY_ADD_USER_TEMPLATE';
export const OUTLAY_DELETE_USER_TEMPLATE = 'OUTLAY_DELETE_USER_TEMPLATE';

/* DOC */
export const OUTLAY_ADD_DOC = 'OUTLAY_ADD_DOC';
export const OUTLAY_UPDATE_DOC = 'OUTLAY_UPDATE_DOC';
export const OUTLAY_DELETE_DOC = 'OUTLAY_DELETE_DOC';
