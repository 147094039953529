import * as constants  from './constants';

type ActionType = (typeof constants)[keyof typeof constants];

const { SESSION_SET_EVENTS, SESSION_ADD_EVENT, SESSION_ARCHIVE_EVENT } = constants;

const initialState = {
  events: <{ [key: string]: any }[]>[],
};

export const sessionReducer = (
  state = initialState,
  { type, payload }: { type: ActionType, payload: any },
): typeof initialState => {
  switch (type) {
    case SESSION_SET_EVENTS: return {
      ...state,
      events: payload.events,
    };
    case SESSION_ADD_EVENT: return {
      ...state,
      events: [...state.events, payload.event],
    };
    case SESSION_ARCHIVE_EVENT: return {
      ...state,
      events: state.events.map((event) => {
        if (event.id !== payload.eventId) return event;
        return { ...event, isArchived: true };
      }),
    };
    default:
      return state;
  }
};
