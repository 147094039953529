import { clone } from 'shared/utils';
import { augmentWork } from './augment';

export const parseOutlay = (outlay, workTypes, matTypes) => {
  outlay = clone(outlay);
  
  if (outlay.type === 'act') {
    outlay.discount = 0;

    for (const room of outlay.rooms) {
      const works = [];
      for (const work of room.roomPositions) {
        augmentWork(work, room, workTypes, matTypes, outlay.version.hasRid);

        if (+work.actNumber !== -2) {
          works.push(work);

        } else {
          works.push(work);
          works.push(...work.actQuantities.map((quantityManual, actNumber) => ({
            ...work,
            id: `FAKE-${work.id}`,
            uuid: `FAKE-${work.uuid}`,
            actNumber,
            quantityManual,
            isSurrogate: true,
          })));
        }
      }
      room.roomPositions = works;
    }

  } else {
    for (const room of outlay.rooms) {
      for (const work of room.roomPositions) {
        augmentWork(work, room, workTypes, matTypes, outlay.version.hasRid);
      }
    }
  }

  return outlay;
};
