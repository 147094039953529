export const VERSION_IDB_STORE_NAMES = [
  'discountConstraints',
  'outlayVariables',
  'promotions',
  'positions',
  'complexPositions',
  'templates',
  'materialTypes',
  'workTypes',
] as const;

export const MAX_CACHE_SIZE = 5;
export const MAX_POLL_VERSION = 100;
export const VER_STORE_PREFIX = 'context-v';
