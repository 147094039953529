import { IDBPDatabase, IDBPTransaction, OpenDBCallbacks, StoreNames } from 'idb';

import { StaticIdbSchema } from './static';
import { VersionIdbSchema } from './version';

export const getStore = <S extends StaticIdbSchema | VersionIdbSchema>(
  idb: IDBPDatabase<S>,
  transaction: IDBPTransaction<S, StoreNames<S>[], 'versionchange'>,
  storeName: StoreNames<S>
) => {
  const storeExists = idb.objectStoreNames.contains(storeName);
  if (!storeExists) return idb.createObjectStore(storeName, { keyPath: 'id' });

  return transaction.objectStore(storeName);
};

export const updateStoresFactory = <S extends StaticIdbSchema | VersionIdbSchema>(
  tables: readonly StoreNames<S>[],
  data?: { [key: string]: any }
) => {
  if (!data) return;

  const updateStores: OpenDBCallbacks<S>['upgrade'] = async (
    idb,
    _oldVersion,
    _newVersion,
    transaction,
  ) => {
    idb.addEventListener('versionchange', () => idb.close());

    for (const table of tables) {
      const store = getStore(idb, transaction, table);

      await store.clear();
      for (const values of data[table]) {
        store.add(values);
      }
    }

    await transaction.done;
  };

  return updateStores;
};
