import { decline, Name, NameObject, NameTuple } from './declension';

type NameEntity = { surname?: string, firstName?: string, middleName?: string, gender?: string };
type NomenclateName = string | NameEntity | Name;
type NomenclateFormat = 'short' | 'abbr' | 'nom' | 'gen' | 'dat' | 'acc' | 'ins' | 'prep';

const abbreviate = (name: NameTuple) => name
  .reduce<string>((result, namePart) => result + (namePart?.[0] || ''), '');

const shorten = ([last, first, middle]: NameTuple) => {
  if (!last) return `${first || ''} ${middle || ''}`.trim();
  
  let result = `${last} `;
  if (first) result += `${first[0]}.`;
  if (middle) result += `${middle[0]}.`;
  return result.trim();
};

const fitNameToTuple = (name: NomenclateName): NameTuple => {
  if (Array.isArray(name)) return name;
  if (typeof name === 'string') return name.split(' ') as NameTuple;
  const lastName = (name as NameEntity).surname || (name as NameObject).lastName;
  return [lastName, name.firstName, name.middleName, (name as NameObject).gender];
};

export const nomenclate = (name: NomenclateName, format: NomenclateFormat = 'short') => {
  const nameTuple = fitNameToTuple(name);
  if (format === 'short') return shorten(nameTuple);
  if (format === 'abbr') return abbreviate(nameTuple);
  return decline(nameTuple, format);
};
