import {
  OUTLAY_ADD_ROOM,
  OUTLAY_CHANGE_POSITION_ORDER,
  OUTLAY_CHANGE_ROOM_ORDER,
  OUTLAY_CHANGE_SELECTED,
  OUTLAY_CUSTOM_POSITION_CHANGE_NAME,
  OUTLAY_DELETE_ROOM,
  OUTLAY_EDIT_SELECTED_OUTLAY,
  OUTLAY_POSITION_CHANGE_ACT_NUMBER,
  OUTLAY_POSITION_CHANGE_PRICE_MANUAL,
  OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL,
  OUTLAY_POSITION_CHANGE_UNIT,
  OUTLAY_POSITION_TURN,
  OUTLAY_RECALCULATE_VALUES,
  OUTLAY_ROOM_ADD_POSITIONS,
  OUTLAY_EDIT_PROMOTION,
  OUTLAY_ROOM_CHANGE_BATHROOM_STATUS,
  OUTLAY_ROOM_CHANGE_HEIGHT,
  OUTLAY_ROOM_CHANGE_LENGTH,
  OUTLAY_ROOM_CHANGE_NAME,
  OUTLAY_ROOM_CHANGE_OPENINGS,
  OUTLAY_ROOM_CHANGE_PARAMS,
  OUTLAY_ROOM_CHANGE_WIDTH,
  OUTLAY_ROOM_DELETE_POSITION,
  OUTLAY_CHANGE_ACT_NUMBER,
  OUTLAY_EDIT_SUB_ACT,
  OUTLAY_CREATE_SUB_ACT,
  OUTLAY_ADD_PROMOTION,
  OUTLAY_REMOVE_PROMOTION,
  OUTLAY_ROOM_CHANGE_TYPE,
  OUTLAY_POSITION_CHANGE_ACT_QUANTITY,
  OUTLAY_SET_PARENT_OUTLAY,
  OUTLAY_IDB_VERSION_CHANGE,
  OUTLAY_SET_USER_TEMPLATES,
  OUTLAY_ADD_USER_TEMPLATE,
  OUTLAY_DELETE_USER_TEMPLATE,
  OUTLAY_ADD_DOC,
  OUTLAY_UPDATE_DOC,
  OUTLAY_DELETE_DOC,
} from '../constants';

export const changeSelectedOutlay = (data) => ({
  type: OUTLAY_CHANGE_SELECTED,
  payload: { data },
});

export const addRoomToOutlay = (data) => ({
  type: OUTLAY_ADD_ROOM,
  payload: { data },
});

export const deleteRoomFromOutlay = (roomId) => ({
  type: OUTLAY_DELETE_ROOM,
  payload: { roomId },
});

export const addPositionsToRoom = (
  /** @type {number} */ roomId,
  /** @type {{}[]} */ roomPositions
) => ({
  type: OUTLAY_ROOM_ADD_POSITIONS,
  payload: { roomId, roomPositions },
});

export const addPromotion = (outlayId, promotion) => ({
  type: OUTLAY_ADD_PROMOTION,
  payload: { outlayId, promotion },
});

export const removePromotion = (outlayId, promotionId) => ({
  type: OUTLAY_REMOVE_PROMOTION,
  payload: { outlayId, promotionId },
});

export const editPromotion = (outlayId, promotionId, data) => ({
  type: OUTLAY_EDIT_PROMOTION,
  payload: { outlayId, promotionId, data },
});

export const deletePositionFromRoom = (roomId, roomPositionId) => ({
  type: OUTLAY_ROOM_DELETE_POSITION,
  payload: { roomId, id: roomPositionId },
});

export const changePositionQuantityManual = (roomId, roomPositionId, quantity) => ({
  type: OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL,
  payload: { roomId, roomPositionId, quantity },
});

export const changePositionActQuantity = (roomId, roomPositionId, actQuantities) => ({
  type: OUTLAY_POSITION_CHANGE_ACT_QUANTITY,
  payload: { roomId, roomPositionId, actQuantities },
});

export const changePositionPriceManual = (roomId, roomPositionId, price) => ({
  type: OUTLAY_POSITION_CHANGE_PRICE_MANUAL,
  payload: { roomId, roomPositionId, price },
});

export const turnPosition = (roomId, roomPositionId, value) => ({
  type: OUTLAY_POSITION_TURN,
  payload: { roomId, roomPositionId, value },
});

export const changeRoomName = (roomId, name) => ({
  type: OUTLAY_ROOM_CHANGE_NAME,
  payload: { roomId, name },
});

export const changeRoomType = (roomId, roomType) => ({
  type: OUTLAY_ROOM_CHANGE_TYPE,
  payload: { roomId, roomType },
});

export const changeRoomWidth = (roomId, data) => ({
  type: OUTLAY_ROOM_CHANGE_WIDTH,
  payload: { roomId, data },
});

export const changeRoomHeight = (roomId, data) => ({
  type: OUTLAY_ROOM_CHANGE_HEIGHT,
  payload: { roomId, data },
});

export const changeRoomOpenings = (roomId, data) => ({
  type: OUTLAY_ROOM_CHANGE_OPENINGS,
  payload: { roomId, data },
});

export const changeRoomLength = (roomId, data) => ({
  type: OUTLAY_ROOM_CHANGE_LENGTH,
  payload: { roomId, data },
});

export const changeRoomParams = (roomId, data) => ({
  type: OUTLAY_ROOM_CHANGE_PARAMS,
  payload: { roomId, data },
});

export const recalculateOutlay = (resultBuffer) => ({
  type: OUTLAY_RECALCULATE_VALUES,
  payload: { ...resultBuffer },
});

export const changeBathroomStatus = (roomId, value) => ({
  type: OUTLAY_ROOM_CHANGE_BATHROOM_STATUS,
  payload: { roomId, value },
});

export const editSelectedOutlay = (data, doNotUpdate) => ({
  type: OUTLAY_EDIT_SELECTED_OUTLAY,
  payload: { data },
  doNotUpdate: doNotUpdate,
});

export const changeCustomPositionName = (roomId, positionId, name) => ({
  type: OUTLAY_CUSTOM_POSITION_CHANGE_NAME,
  payload: { roomId, positionId, name },
});

export const changeRoomsOrder = (roomId, sourceIndex, destinationIndex) => ({
  type: OUTLAY_CHANGE_ROOM_ORDER,
  payload: { roomId, sourceIndex, destinationIndex },
});

export const changePositionsOrder = (positions, room, type, id, sourceIndex, destinationIndex) => ({
  type: OUTLAY_CHANGE_POSITION_ORDER,
  payload: { positions, room, type, id, sourceIndex, destinationIndex },
});

export const changePositionUnit = (roomId, positionId, unit) => ({
  type: OUTLAY_POSITION_CHANGE_UNIT,
  payload: { roomId, positionId, unit },
});

export const changePositionActNumber = (roomId, roomPositionId, actNumber) => ({
  type: OUTLAY_POSITION_CHANGE_ACT_NUMBER,
  payload: { roomId, roomPositionId, actNumber },
});

export const setParentOutlay = (outlay) => ({
  type: OUTLAY_SET_PARENT_OUTLAY,
  payload: { outlay },
});

/* SUB_ACT */
export const changeActNumber = (actNumber) => ({
  type: OUTLAY_CHANGE_ACT_NUMBER,
  payload: { actNumber },
});

export const editSubAct = (outlayId, actNumber, data) => ({
  type: OUTLAY_EDIT_SUB_ACT,
  payload: { outlayId, actNumber: parseInt(actNumber), data },
});

export const createSubAct = (subAct) => ({
  type: OUTLAY_CREATE_SUB_ACT,
  payload: { subAct },
});

export const idbVersionChange = () => ({
  type: OUTLAY_IDB_VERSION_CHANGE,
});

/* USER_TEMPLATE */
export const setUserTemplates = (userTemplates) => ({
  type: OUTLAY_SET_USER_TEMPLATES,
  payload: userTemplates,
});

export const addUserTemplate = (userTemplate) => ({
  type: OUTLAY_ADD_USER_TEMPLATE,
  payload: { userTemplate },
});

export const deleteUserTemplate = (userTemplateId) => ({
  type: OUTLAY_DELETE_USER_TEMPLATE,
  payload: { userTemplateId },
});

export const addDoc = (doc) => ({
  type: OUTLAY_ADD_DOC,
  payload: { doc },
});

export const updateDoc = (doc) => ({
  type: OUTLAY_UPDATE_DOC,
  payload: { doc },
});

export const deleteDoc = (docId) => ({
  type: OUTLAY_DELETE_DOC,
  payload: { docId },
});
