import { logger } from './log';

export const perf = (() => {
  if (__APP_ENV__ === 'production') {
    const fnRef = () => undefined;
    return () => fnRef;
  }

  return ((prefix: string) => {
    const start = performance.now();

    return (logThreshold: number = 99, warnThreshold: number = 999) => {
      const result = performance.measure('end', { start }).duration | 0;

      if (result > warnThreshold) logger.warn(prefix, `${result}ms`);
      else if (result > logThreshold) logger.log(prefix, `${result}ms`);
    };
  });

})();
