export const MUL_OP_MAP = { '*': 'mul', '/': 'div' } as const;
export type MulOpMap = typeof MUL_OP_MAP;
export type MulOpKey = keyof MulOpMap;
export type MulOpValue = MulOpMap[MulOpKey];

export const ADD_OP_MAP = { '+': 'add', '-': 'sub' } as const;
export type AddOpMap = typeof ADD_OP_MAP;
export type AddOpKey = keyof AddOpMap;
export type AddOpValue = AddOpMap[AddOpKey];

export const COMP_OP_MAP = { '=': 'eq', '>=': 'gte', '<=': 'lte', '>': 'gt', '<': 'lt' } as const;
export type CompOpMap = typeof COMP_OP_MAP;
export type CompOpKey = keyof CompOpMap;
export type CompOpValue = CompOpMap[CompOpKey];
