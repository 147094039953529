const deriveOutlayAndOrg = (selectedOutlay, selectedDeal) => {
  if (!selectedOutlay) return null;
  if (!selectedDeal) return null;

  const outlay = { ...selectedOutlay };

  outlay.brand = selectedDeal.brand.name;

  if (selectedDeal.organizationManual) {
    outlay.org = selectedDeal.organizationManual.name;

  } else if (outlay.VAT) {
    outlay.org = selectedDeal.brand.vatOrganization.name;

  } else if (outlay.installment) {
    outlay.org = selectedDeal.brand.installmentOrganization.name;

  } else {
    outlay.org = selectedDeal.brand.defaultOrganization.name;
  }

  return outlay;
};

export default deriveOutlayAndOrg;
