export const LOAD_EVENT = new Event('load');

export const UNLOAD_EVENT = new Event('unload');

export class NoIdbError extends Error {
  constructor() {
    super('IndexedDB is not initialized');
    this.name = 'NoIdbError';
  }
}

const DEF_ERROR = new NoIdbError();

export const NO_IDB_PROMISE = {
  then(
    _onfulfilled?: (() => any) | undefined | null,
    onrejected?: ((reason: NoIdbError) => any) | undefined | null,
  ) {
    if (onrejected) queueMicrotask(() => onrejected(DEF_ERROR));
    return this;
  },
  catch(onrejected?: ((reason: NoIdbError) => any) | undefined | null) {
    if (onrejected) queueMicrotask(() => onrejected(DEF_ERROR));
    return this;
  },
  finally(onfinally?: (() => void) | undefined | null) {
    if (onfinally) queueMicrotask(onfinally);
    return this;
  },
} as Promise<any>;
