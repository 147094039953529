import { StoreNames, StoreValue } from 'idb';
import { useEffect, useState, useSyncExternalStore } from 'react';

import { getStaticIdb, getVersionIdb, NoIdbError, StaticIdbSchema, VersionIdbSchema } from 'shared/idb';
import { logger } from 'shared/utils';

type IdbName = 'static' | 'version';
type IdbSchema<I extends IdbName> = { static: StaticIdbSchema, version: VersionIdbSchema }[I];

const buildIdbHook = <I extends IdbName, S extends IdbSchema<I>, N extends StoreNames<S>>(
  idbName: I,
  storeName: N,
) => {
  const idbController = idbName === 'static' ? getStaticIdb() : getVersionIdb();

  const idbSubscribe = (notify: () => void) => {
    idbController.addEventListener('load', notify);
    idbController.addEventListener('unload', notify);
    return () => {
      idbController.removeEventListener('load', notify);
      idbController.removeEventListener('unload', notify);
    };
  };

  // @ts-ignore
  const idbGetSnapshot = () => idbController.getAll(storeName) as Promise<StoreValue<S, N>[]>;

  return () => {
    const promise = useSyncExternalStore(idbSubscribe, idbGetSnapshot);

    const [data, setData] = useState<StoreValue<S, N>[] | null>(null);

    useEffect(() => {
      promise
        .then((values) => setData(values as StoreValue<S, N>[]))
        .catch((error) => {
          if (!(error instanceof NoIdbError))
            logger.error('idb_hook', error);
          setData(null);
        });
    }, [promise]);

    return data;
  };
};

export const useIdbRoomTypes = buildIdbHook('static', 'roomTypes');
export const useIdbOrgs = buildIdbHook('static', 'organizations');
export const useIdbWorkConditions = buildIdbHook('static', 'workConditionTypes');
export const useIdbDesignProjects = buildIdbHook('static', 'designProjectTypes');
export const useIdbUserTypes = buildIdbHook('static', 'userTypes');

export const useIdbWorkTypes = buildIdbHook('version', 'workTypes');
export const useIdbComplexPositions = buildIdbHook('version', 'complexPositions');
export const useIdbPromotions = buildIdbHook('version', 'promotions');
