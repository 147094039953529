import moment, { Moment } from 'moment';

const opts: Intl.DateTimeFormatOptions = { timeZone: 'Europe/Moscow' };
const optsShort: Intl.DateTimeFormatOptions = {
  timeZone: 'Europe/Moscow',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

const fitToDate = (timestamp: any): Date => {
  if (moment.isMoment(timestamp)) return timestamp.toDate();
  if (timestamp instanceof Date) return timestamp;
  return new Date(timestamp);
};

const isValid = (date: any) => {
  if (!date) return false;
  if (!date?.toString) return false;
  return date.toString() !== 'Invalid Date';
};

export const localize = (
  timestamp: string | Date | Moment,
  format: 'full' | 'date' | 'time' | 'short' = 'full',
  locale: string = 'ru'
): string | null => {
  const result = fitToDate(timestamp);
  if (!isValid(result)) return null;

  if (format === 'date') return result.toLocaleDateString(locale, opts);
  if (format === 'time') return result.toLocaleTimeString(locale, opts);
  return result.toLocaleString(locale, format === 'short' ? optsShort : opts);
};
