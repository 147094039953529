import { deleteDB } from 'idb';
import { StaticIdbController } from './static';
import { VersionIdbController } from './version';

export const getStaticIdb = () => window.staticIdb || (window.staticIdb = new StaticIdbController());

export const getVersionIdb = () => window.versionIdb || (window.versionIdb = new VersionIdbController());

export const loadIdb = () => Promise.all([
  getStaticIdb().load(),
  getVersionIdb().load(),
]);

export const unloadIdb = () => {
  getStaticIdb().unload();
  getVersionIdb().unload();
};

export const clearIdb = async () => {
  unloadIdb();
  const entries = await indexedDB.databases();
  await Promise.all(entries.map(({ name }) => deleteDB(name!)));
};
